import React, { ReactNode } from 'react';
import {
  Pressable, useWindowDimensions,
} from 'react-native';
import responsiveDimensions from '../helpers/responsiveDimensions';

type Props = {
  onPress(): void;
  active: boolean;
  children?: ReactNode;
  isDisabled: boolean;
  moduleWidth?: number;
  moduleHeight?: number;
  numberOfSpaces?: number;
  selectable: boolean;
};

export default function ModuleBox({
  onPress, active, children, moduleHeight, moduleWidth, numberOfSpaces, selectable = false, isDisabled,
} : Props) {
  const { width } = useWindowDimensions();
  numberOfSpaces = numberOfSpaces || 1;
  const adjustedHeight = () => {
    const offSet = numberOfSpaces > 1 ? 2 * numberOfSpaces : 0;
    return (numberOfSpaces * (moduleHeight || 70)) + offSet;
  };
  const adjustedWidth = moduleWidth || 185;

  const backgroundColor = (): string => {
    if (active) {
      return '#aecccf';
    }
    if (selectable) {
      return '#cfcfcf';
    }
    return '#fefefe';
  };

  return (
    <Pressable
      disabled={isDisabled}
      onPress={onPress}
      style={{
        alignItems: 'flex-start',
        backgroundColor: backgroundColor(),
        borderColor: '#02969e',
        borderRadius: 3,
        borderWidth: 2,
        height: responsiveDimensions(adjustedHeight(), width),
        justifyContent: 'center',
        margin: 2,
        paddingLeft: 5,
        width: responsiveDimensions(adjustedWidth, width),
      }}
    >
      {children}
    </Pressable>
  );
}

ModuleBox.defaultProps = {
  children: null,
};
