import React, { useState } from 'react';
import {
  View, StyleSheet,
} from 'react-native';
import { Button as EButton, Icon } from 'react-native-elements';
import FinishSelectionScreen from './FinishSelectionScreen';
import LiningSelectionScreen from './LiningSelectionScreen';
import DetailingSelectionScreen from './DetailingSelectionScreen';
import { SelectedModule } from '../../types/index';
import { useAppSelector } from '../../hooks/store';
import SafeColourSelectionScreen from './SafeColourSelectionScreen';
import ScrollViewHeader from '../../components/ScrollViewHeader';

type Props = {
  currentSelection: SelectedModule;
}

const styles = StyleSheet.create({
  tabContainer: {
    margin: 5,
  },
  tabButton: {
    borderRadius: 12,
    backgroundColor: '#fff',
    borderWidth: 2,
    borderColor: '#02969e',
  },
  tabTitle: {
    padding: 10,
    color: '#02969e',
  },
  tabButtonSelected: {
    borderRadius: 12,
    borderWidth: 2,
    borderColor: '#02969e',
    backgroundColor: '#02969e',
  },
  tabTitleSelected: {
    padding: 10,
    color: '#fff',
  },
});

export default function TabOptions({
  currentSelection,
} : Props) {
  const [tabName, setTabName] = useState('finishes');
  const { product } = useAppSelector(({ product }) => product);
  const selectedSize = useAppSelector(({ product }) => product.size);
  const selectedModules = useAppSelector(({ product }) => product.selectedModules);
  const displayLiningOption = Object.values(selectedModules).some((sm) => sm.display_lining_label);

  const headerName = () => {
    switch (tabName) {
      case 'finishes':
        return product?.finish_label || 'Outside Faux Leather';
      case 'linings':
        return product?.lining_label || 'Inside Velvet';
      case 'safe_colours':
        return product?.colour_label || 'Safe Colour';
      case 'detailings':
        return 'Detailing';
      default:
        return 'Colour options';
    }
  };

  return (
    <View style={{ flex: 1 }}>
      <ScrollViewHeader title={headerName()} />
      <View
        style={{
          display: 'flex', justifyContent: 'center', flexDirection: 'row', flexWrap: 'wrap', marginVertical: 10,
        }}
      >
        <EButton
          titleStyle={[tabName === 'finishes' ? styles.tabTitleSelected : styles.tabTitle]}
          buttonStyle={[tabName === 'finishes' ? styles.tabButtonSelected : styles.tabButton]}
          containerStyle={styles.tabContainer}
          title={product.finish_label ? product.finish_label : 'Outside Faux Leather'}
          onPress={() => { setTabName('finishes'); }}
        />
        {
          displayLiningOption
            ? (
              <EButton
                titleStyle={[tabName === 'linings' ? styles.tabTitleSelected : styles.tabTitle]}
                buttonStyle={[tabName === 'linings' ? styles.tabButtonSelected : styles.tabButton]}
                containerStyle={styles.tabContainer}
                title={product.lining_label ? product.lining_label : 'Inside Velvet'}
                onPress={() => { setTabName('linings'); }}
              />
            ) : null
        }
        {
          selectedSize?.safe_colours?.length
            ? (
              <EButton
                titleStyle={[tabName === 'safe_colours' ? styles.tabTitleSelected : styles.tabTitle]}
                buttonStyle={[tabName === 'safe_colours' ? styles.tabButtonSelected : styles.tabButton]}
                containerStyle={styles.tabContainer}
                title={product.colour_label ? product.colour_label : 'Safe Colour'}
                onPress={() => { setTabName('safe_colours'); }}
              />
            ) : null
        }
        {
          selectedSize?.detailings?.length
            ? (
              <EButton
                titleStyle={[tabName === 'detailings' ? styles.tabTitleSelected : styles.tabTitle]}
                buttonStyle={[tabName === 'detailings' ? styles.tabButtonSelected : styles.tabButton]}
                containerStyle={styles.tabContainer}
                title="Detailing"
                onPress={() => { setTabName('detailings'); }}
              />
            ) : null
        }
      </View>
      {
        tabName === 'finishes' ? (
          <FinishSelectionScreen currentSelection={currentSelection} />
        ) : null
      }

      {
        tabName === 'linings' ? (
          <LiningSelectionScreen currentSelection={currentSelection} />
        ) : null
      }

      {
        tabName === 'safe_colours' && selectedSize?.safe_colours?.length ? (
          <SafeColourSelectionScreen />
        ) : null
      }

      {
        tabName === 'detailings' && selectedSize?.detailings?.length ? (
          <DetailingSelectionScreen />
        ) : null
      }
    </View>
  );
}
