import React from 'react';
import {
  Text, Platform, Pressable, View, StyleSheet, Image,
} from 'react-native';
import Grade from '../types/grade';

interface Props {
  active: boolean;
  grade: Grade;
  onPress(): void;
}

const isApp = Platform.OS !== 'web';

const styles = StyleSheet.create({
  active: {
    borderColor: '#02969e',
  },
  container: {
    alignItems: 'center',
    backgroundColor: '#f6f6f6',
    borderRadius: 20,
    borderWidth: 2,
    justifyContent: 'flex-start',
    margin: 10,
    marginVertical: isApp ? 5 : 10,
    overflow: 'hidden',
    paddingBottom: 20,
    width: 260,
  },
  description: {
    color: '#000',
    fontFamily: 'nimbus',
    marginHorizontal: 15,
    marginBottom: 0,
    marginTop: 0,
    lineHeight: 30,
    textAlign: 'center',
  },
  heading: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    color: '#000',
    fontSize: 30,
    marginVertical: 10,
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  inactive: {
    borderColor: 'rgba(158, 150, 150, 0)',
  },
  ratingContainer: {
    padding: isApp ? 5 : 10,
    width: '100%',
  },
  ratingText: {
    fontFamily: 'nimbus',
    fontWeight: 'bold',
    marginVertical: isApp ? 10 : 15,
    textAlign: 'center',
  },
  vibrant: {
    backgroundColor: '#f6f6f6',
  },
});

export default function GradeOption({ grade, onPress, active } : Props) {
  return (
    <Pressable
      onPress={onPress}
      style={[styles.container, active ? styles.active : styles.inactive]}
    >
      <View
        style={{
          alignItems: 'center', marginTop: 20, paddingTop: 10, paddingBottom: isApp ? 5 : 10,
        }}
      >
        <Image
          source={{ uri: grade.logo }}
          style={{ width: 210, height: 46, resizeMode: 'contain' }}
        />
      </View>
      <View style={styles.ratingContainer}>
        <Text style={styles.ratingText}>{grade.cash_rating}</Text>
        <Text style={styles.ratingText}>{grade.jewellery_rating}</Text>
      </View>
      <View style={[
        { marginHorizontal: 10, padding: 10 }, active ? styles.vibrant : styles.inactive,
      ]}
      >
        <Text style={styles.description}>{grade.description}</Text>
      </View>
    </Pressable>
  );
}
