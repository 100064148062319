import React, { useState } from 'react';
import { Image, Pressable, ScrollView, StyleSheet, Text, View } from 'react-native';
import { useAppDispatch, useAppSelector } from '../hooks/store';
import {
  Detailing, Drawer, DrawerLining, Grade, Module, Preset, Product, Size,
} from '../../types/index';
import {
  updateGrade, updateProduct, updateDetailing, updateSafeColour, updateSelectedDrawers,
  updateSelectedFinish, updateSelectedLining, updateSelectedModules, updateSize,
} from '../store/productSlice';
import ConditionalButton from './ConditionalButton';

const styles = StyleSheet.create({
  activePreset: {
    borderColor: '#00959a',
  },
  colourSample: {
    borderRadius: 10,
    borderWidth: 0.5,
    height: 20,
    marginTop: 10,
    marginRight: 5,
    width: 20,
  },
  heading: {
    fontFamily: 'eurostile',
    fontSize: 40,
    marginTop: 25,
    textAlign: 'center',
  },
  pageMargin: {
    alignSelf: 'center',
    maxWidth: 1200,
    width: '80%',
  },
  paragraph: {
    fontFamily: 'nimbus',
    fontSize: 24,
    textAlign: 'center',
  },
  presetContainer: {
    alignItems: 'center',
    backgroundColor: '#f6f6f6',
    borderColor: '#f6f6f6',
    borderRadius: 20,
    borderWidth: 2,
    flexDirection: 'column',
    justifyContent: 'center',
    marginHorizontal: 20,
    paddingVertical: 50,
    width: 300,
  },
  presetImage: {
    height: 300,
    width: 250,
  },
  presetHeading: {
    fontWeight: 'bold',
    fontSize: 20,
    marginTop: 10,
  },
});

const PresetSelect = ({ navigation, presets, products }) => {
  const [selectedPreset, setPreset] = useState();

  const drawerPayload = (
    selectedModule: Module, drawerPosition: number, selectedDrawer: Drawer,
  ) => ({
    selectedModule,
    drawerPosition,
    selectedDrawer,
  });

  const dispatch = useAppDispatch();
  const savedModules = useAppSelector(({ product }) => product.selectedModules);
  const savedSize = useAppSelector(({ product }) => product.size);

  const onPress = (preset: Preset) => {
    const { detailing_id, grade_id, modules, product_id, size_id } = preset;
    const selectedProduct = products.find((p: Product) => p.id === product_id);
    const selectedGrade = selectedProduct.grades.find((g: Grade) => g.id === grade_id);
    const selectedSize = selectedGrade.sizes.find((s: Size) => s.id === size_id);
    const selectedDetailing = selectedSize.detailings.find((d: Detailing) => d.id === detailing_id);
    const selectedModules: Array<Module> = [];
    modules.forEach((module) => {
      selectedModules.push(selectedSize.modules.find((m) => m.id === module.id));
    });
    dispatch(updateProduct(selectedProduct));
    dispatch(updateGrade(selectedGrade));
    dispatch(updateSize(selectedSize));
    if (selectedDetailing) dispatch(updateDetailing(selectedDetailing));
    setPreset(preset);
    selectedModules.forEach((selectedModule: Module, i: number) => {
      dispatch(updateSelectedModules({ position: i + 1, selected: selectedModule }));
    });
  };

  const next = () => {
    if (!selectedPreset || !savedSize || !savedModules) {
      return;
    }
    // workaround: drawers need to be set AFTER modules have been saved to product store
    const selectedValues = Object.values(savedModules);

    selectedValues.forEach((m) => {
      const selectedLining: DrawerLining = savedSize.drawer_linings.find((dl) => dl.id === selectedPreset.lining_id);
      const selectedFinish = savedSize.module_finishes.find((mf) => mf.id === selectedPreset.finish_id);
      const selectedColour = savedSize.safe_colours.find((sc) => sc.id === selectedPreset.safe_colour_id);

      const finishData = { selectedModule: m, finish: selectedFinish };
      const liningData = { selectedModule: m, lining: selectedLining };

      if (selectedColour) dispatch(updateSafeColour(selectedColour));
      if (selectedLining) dispatch(updateSelectedLining(liningData));
      if (selectedFinish) dispatch(updateSelectedFinish(finishData));

      const filteredModule = selectedPreset.modules.find((sm) => sm.position === m.position);
      filteredModule.drawer_ids.forEach((drawerId: number, i: number) => {
        const selectedDrawer = savedSize.drawers.find((d: Drawer) => d.id === drawerId);
        dispatch(
          updateSelectedDrawers(
            drawerPayload(
              m, (i + 1), selectedDrawer,
            ),
          ),
        );
      });
    });
    navigation.navigate('Customer Details');
  };

  return (
    <View>
      <View style={styles.pageMargin}>
        <Text style={styles.heading}>
          Available in Stock
        </Text>
        <Text style={styles.paragraph}>
          We have a selection of predesigned safes in stock, ready for delivery.
        </Text>
      </View>
      <ScrollView
        horizontal
        style={{ marginTop: 20, maxWidth: '100%', flexGrow: 0, alignSelf: 'center' }}
        contentContainerStyle={{ justifyContent: 'space-evenly', paddingVertical: 20 }}
      >
        {
          products ? (
            presets.map((p) => (
              <Pressable
                key={p.id}
                style={[
                  styles.presetContainer,
                  selectedPreset && p.id === selectedPreset.id ? styles.activePreset : null,
                ]}
                onPress={() => onPress(p)}
              >
                <View style={{ marginBottom: 20 }}>
                  <Image
                    source={{ uri: p.image }}
                    style={styles.presetImage}
                    resizeMode="contain"
                  />
                </View>
                <View style={{ paddingHorizontal: 20, width: '100%' }}>
                  <Text style={styles.presetHeading}>
                    { p.name }
                  </Text>
                  <Text style={{ color: '#444', fontStyle: 'italic', marginTop: 5 }}>
                    { p.description }
                  </Text>
                  <Text style={{ marginTop: 5 }}>Colour option</Text>
                  <View style={[styles.colourSample, { backgroundColor: `${p.safe_colour_preview}` }]} />
                </View>
              </Pressable>
            ))
          ) : (
            <></>
          )
        }
      </ScrollView>
      <View style={{ alignSelf: 'center', alignItems: 'center', marginVertical: 50, width: 300 }}>
        <ConditionalButton condition={!!selectedPreset} onPress={() => next()} title="Continue" />
      </View>
    </View>
  );
};

export default PresetSelect;
