import React from 'react';
import {
  Image, StyleSheet, Text, View,
} from 'react-native';
import { Detailing, Grade, Product, SafeColour } from '../types';

type Props = {
  detailing: Detailing;
  grade: Grade;
  product: Product;
  safeColour: SafeColour;
  sizeName: string;
};

const styles = StyleSheet.create({
  keyText: {
    fontWeight: 'bold',
  },
  logoContainer: {
    alignItems: 'center',
    marginBottom: 10,
  },
  logoImage: {
    height: 40,
    resizeMode: 'contain',
    width: 200,
  },
  nameContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
  },
  outerContainer: {
    alignItems: 'center',
    borderTopColor: '#02969e',
    borderTopWidth: 1,
    borderBottomColor: '#02969e',
    borderBottomWidth: 1,
    marginTop: 30,
    paddingHorizontal: 10,
    paddingVertical: 20,
  },
});

const KeyValueText = (
  { keyText, valueText } : { keyText: string, valueText: string },
) => (
  <View style={{ flexDirection: 'row', flexWrap: 'nowrap', marginBottom: 5 }}>
    <Text style={styles.keyText}>{ `${keyText} ` }</Text>
    <Text>{ valueText }</Text>
  </View>
);

const ProductDetailsHeader = ({ detailing, grade, product, safeColour, sizeName } : Props) => (
  <View style={styles.outerContainer}>
    <View style={{ width: '90%' }}>
      <View style={styles.logoContainer}>
        <Image source={{ uri: grade.logo }} style={styles.logoImage} />
      </View>
      <View style={styles.nameContainer}>
        { product.name ? (<KeyValueText keyText="Range:" valueText={product.name} />) : null}
        { grade ? (<KeyValueText keyText="Grade:" valueText={grade.name} />) : null}
        { sizeName ? (<KeyValueText keyText="Size:" valueText={sizeName} />) : null}
        { safeColour ? (<KeyValueText keyText="Safe Colour:" valueText={safeColour.name} />) : null}
        { detailing ? (<KeyValueText keyText="Detailing:" valueText={detailing.name} />) : null}
      </View>
    </View>
  </View>
);

export default ProductDetailsHeader;
