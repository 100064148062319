import React, { useState, useEffect } from 'react';
import {
  View, useWindowDimensions, ScrollView,
} from 'react-native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import BackButton from '../../components/BackButton';
import { useAppSelector } from '../../hooks/store';
import { AvailableModule, SelectedModule } from '../../types';
import { ProductStackParamList } from '../../types/routes'
import { Module } from '../../types/index';
import ConditionalButton from '../../components/ConditionalButton';
import AppContainer from '../../components/AppContainer';
import NavigationBar from '../../components/NavigationBar';
import { hasDrawers } from '../../helpers/module_helpers';
import HeaderSection from '../../components/HeaderSection';
import ScrollViewHeader from '../../components/ScrollViewHeader';
import ModuleBuilder from '../../components/ModuleBuilder';
import ModuleSelector from '../../components/ModuleSelector';

type Props = NativeStackScreenProps<ProductStackParamList, 'Select Modules'>

export default function ModulesSelectScreen({ navigation } : Props) {
  const size = useAppSelector(({ product }) => product.size);
  const product = useAppSelector(
    ({ product: productStore }) => productStore.product,
  );
  const selectedModules = useAppSelector(({ product }) => product.selectedModules);
  const { virtualPositions } = useAppSelector(({ product }) => product);
  const [screenLoad, setScreenLoad] = useState<boolean>(true);
  const [modalVisibility, setModalVisibility] = useState<boolean>(false);
  const [allowedModules, setAllowedModules] = useState<Module[]>([]);
  const [availableModules, setAvailableModules] = useState<AvailableModule[]>([]);
  const [currentSelection, setCurrentSelection] = useState<AvailableModule | null>(null);
  const { width } = useWindowDimensions();
  const breakPoint = 700;

  useEffect(() => {
    setAvailableModules(size?.available_modules.filter((am) => !virtualPositions.flat().filter((p) => !Object.values(selectedModules).map(((sm) => sm.position)).includes(p)).includes(am.position)))
  }, [virtualPositions]);

  const setModules = (moduleIds: number[]) => {
    const modules = moduleIds.reduce((accumulator : Module[], currentId : number) => {
      const module = size?.modules.find((m) => m.id === currentId);
      if (module) { accumulator.push(module); }
      return accumulator;
    }, []);
    setAllowedModules(modules);
  };

  if (screenLoad && availableModules.length > 0 && width > 700) {
    setCurrentSelection(availableModules[0]);
    setScreenLoad(false);
  }

  useEffect(() => {
    if (currentSelection) {
      setModules(currentSelection.allowed_modules);
    }
  }, [currentSelection]);

  const onNext = () => {
    const selectedValues = Object.values(selectedModules);

    const drawerSelect = selectedValues.some(
      (item : SelectedModule) => (
        hasDrawers(item)
      ),
    );
    setModalVisibility(false);
    if (drawerSelect) {
      navigation.navigate('Select Drawers');
    } else {
      navigation.navigate('Select Options');
    }
  };

  const canContinue = () => !!(availableModules?.every((am) => !!selectedModules[am.position]));

  const adjustedHeight = size?.safe_height
    || ((size?.available_modules.length || 0) * size?.module_height) + size?.module_height;

  return (
    <>
      <AppContainer>
        <HeaderSection navigation={navigation} />
        <ScrollView contentContainerStyle={{ flex: 1, alignItems: 'center', justifyContent: 'flex-start', maxWidth: '100%' }}>
          <ScrollViewHeader title="Choose a Module for your Valuables" />
          <View style={{ justifyContent: width > breakPoint ? 'flex-start' : 'center', alignItems: 'center', flexDirection: 'row', flexGrow: 1, marginTop: 35, width: '100%' }}>
            {
              product.name === 'Amario Lux' ? (
                <ModuleBuilder
                  adjustedHeight={adjustedHeight}
                  allowedModules={allowedModules}
                  availableModules={availableModules}
                  breakPoint={breakPoint}
                  currentSelection={currentSelection}
                  modalVisibility={modalVisibility}
                  setAvailableModules={setAvailableModules}
                  setCurrentSelection={setCurrentSelection}
                  setModalVisibility={setModalVisibility}
                  selectedModules={selectedModules}
                  size={size}
                  width={width}
                />
              )
                : (
                  <ModuleSelector
                    adjustedHeight={adjustedHeight}
                    allowedModules={allowedModules}
                    availableModules={availableModules}
                    breakPoint={breakPoint}
                    currentSelection={currentSelection}
                    modalVisibility={modalVisibility}
                    selectedModules={selectedModules}
                    setCurrentSelection={setCurrentSelection}
                    setModalVisibility={setModalVisibility}
                    size={size}
                    width={width}
                  />
                )
            }
          </View>
        </ScrollView>
      </AppContainer>
      <NavigationBar>
        <BackButton />
        <ConditionalButton condition={canContinue()} onPress={onNext} title="Next" />
      </NavigationBar>
    </>
  );
}
