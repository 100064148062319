import { useAppDispatch } from '../hooks/store';
import { updateAllProductsData } from '../store/allProductsSlice';
import fetchProducts from '../helpers/fetchProducts';

const AllProductsData = () => {
  const dispatch = useAppDispatch();

  fetchProducts(true)
    .then((data) => {
      dispatch(updateAllProductsData({ products: data }));
    });
  return null;
};

export default AllProductsData;
