import React, { useEffect, useState } from 'react';
import {
  Image, useWindowDimensions, View,
} from 'react-native';
import { useAppSelector } from '../hooks/store';
import ModulePreview from './ModulePreview';
import responsiveDimensions from '../helpers/responsiveDimensions'
import fetchSafeColourImage from '../helpers/fetchSafeColourImage';
import fetchDetailingImage from '../helpers/fetchDetailingImage';

const ProductPreview = () => {
  const selectedModules = useAppSelector(({ product }) => product.selectedModules);
  const selectedSize = useAppSelector(({ product }) => product.size);
  const selectedSafeColour = useAppSelector(({ product }) => product.safeColour);
  const selectedDetailing = useAppSelector(({ product }) => product.detailing);
  const product = useAppSelector(
    ({ product: productStore }) => productStore.product,
  );
  const [safeImage, setSafeImage] = useState(null);
  const [detailingImage, setDetailingImage] = useState(null);

  const modules = Object.values(selectedModules);

  useEffect(() => {
    fetchSafeColourImage(selectedSize?.id, selectedSafeColour?.id)
      .then((json: any) => {
        setSafeImage(json.grade_size_colour_variant_url);
      })
      .catch((error) => console.error(error));

    if (selectedDetailing) {
      fetchDetailingImage(selectedSize?.id, selectedDetailing?.id)
        .then((json: any) => {
          setDetailingImage(json.detailing_colour_variant_url);
        })
        .catch((error) => console.error(error));
    }
  }, []);

  const { width } = useWindowDimensions();
  const breakPoint = 810;
  const moduleHeight = (selectedSize?.module_height || 70);
  const moduleWidth = (selectedSize?.module_width || 185);
  const heightOffset = product?.name === 'Amario Lux' ? 4 : 0;

  const adjustedHeight = selectedSize?.safe_height || (Object.values(selectedModules)
    .map((sm) => sm.no_of_spaces).reduce((a, b) => a + b, 0) * selectedSize?.module_height)
      + (selectedSize?.module_height);

  return (
    <View style={{height: responsiveDimensions(adjustedHeight, width), alignItems: 'center', justifyContent: 'center' }}>
      <View style={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'row', flexGrow: 1, width: '100%' }}>
        <View style={{ width: width > breakPoint ? '50%' : '100%', position: 'absolute', left: width > breakPoint ? '5%' : null, top: 0, alignContent: 'center' }}>
          <View style={{ flexGrow: 0, height: responsiveDimensions(adjustedHeight, width), alignSelf: width > breakPoint ? 'left' : 'center', width: responsiveDimensions(450, width), justifyContent: 'center', paddingLeft: responsiveDimensions(selectedSize?.padding_left || 25, width) }}>
            {
              modules?.map((module: any) => (
                <View key={module.position}>
                  <ModulePreview
                    module={module}
                    productId={`${product.id}`}
                    gradeSizeId={`${selectedSize.id}`}
                    width={responsiveDimensions(modules.length === 1 ? moduleWidth : (moduleWidth + 4), width)}
                    height={responsiveDimensions((moduleHeight * module.no_of_spaces) + (heightOffset * module.no_of_spaces), width)}
                  />
                </View>
              ))
            }
          </View>
        </View>
      </View>
      <View style={{
        alignSelf: 'center',
        width: responsiveDimensions(450, width),
        height: responsiveDimensions(adjustedHeight, width),
        position: 'absolute',
        left: width > breakPoint ? '5%' : null,
        top: 0,
        zIndex: -1,
      }}
      >
        <Image
          style={{ width: '100%', height: '100%' }}
          source={{ uri: detailingImage }}
          resizeMode="contain"
        />
      </View>

      <View style={{
        alignSelf: 'center',
        width: responsiveDimensions(450, width),
        height: responsiveDimensions(adjustedHeight, width),
        position: 'absolute',
        left: width > breakPoint ? '5%' : null,
        top: 0,
        zIndex: -2,
      }}
      >
        <Image
          style={{ width: '100%', height: '100%' }}
          source={{ uri: safeImage }}
          resizeMode="contain"
        />
      </View>
    </View>
  );
};

export default ProductPreview;
