import React from 'react';
import { Image, StyleSheet, Text, View } from 'react-native';
import { useAppSelector } from '../hooks/store';

const styles = StyleSheet.create({
  clientLogo: {
    height: 65,
    marginVertical: 5,
    resizeMode: 'contain',
    width: 200,
  },
  textContainer: {
    backgroundColor: '#f6f6f6',
    paddingBottom: 10,
    paddingHorizontal: 20,
    paddingTop: 5,
    width: '100%',
  },
  footerText: {
    marginVertical: 5,
    textAlign: 'center',
  }
});

export default function Footer() {
  const {
    footer_header_background_colour, footer_text_1, footer_text_2, logoUrl,
  } = useAppSelector(({ client }) => client);

  return (
    <>
      { logoUrl ? (
        <View
          style={{
            alignItems: 'center',
            backgroundColor: footer_header_background_colour || '#f6f6f6',
            justifyContent: 'center',
            marginTop: 20,
            width: '100%',
          }}
        >
          <Image
            source={{ uri: logoUrl }}
            style={styles.clientLogo}
          />
          <View style={styles.textContainer}>
            {
              footer_text_1 ? (
                <Text style={styles.footerText}>
                  {footer_text_1}
                </Text>
              ) : <></>
            }
            {
              footer_text_2 ? (
                <Text style={styles.footerText}>
                  {footer_text_2}
                </Text>
              ) : <></>
            }

          </View>
        </View>
      )
        : (
          null
        )}
    </>
  );
}
