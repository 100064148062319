import baseUrl from './baseUrl';

export default async function fetchSafeColourImage(gradeSizeId, safeColourId) : Promise<any> {
  const apiBase = `${baseUrl}/get_size_colour_variant?safe_colour_id=${safeColourId}&grade_size_id=${gradeSizeId}`;

  try {
    const url = apiBase;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    const data: any = await response.json();
    return data;
  } catch (e) {
    console.log(`ERROR${e}`);

    return Promise.reject();
  }
}
