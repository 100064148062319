import React from 'react';
import {
  Text, TouchableOpacity, View, StyleSheet,
  Image, useWindowDimensions,
} from 'react-native';
import * as Linking from 'expo-linking';
import { useAppSelector } from '../hooks/store';
import { ContentSection } from '../types/index';
import HomeScreenVideo from './HomeScreenVideo';
import isApp from '../helpers/isApp'

interface Props {
  contentSection: ContentSection;
  renderButton: () => void | null;
  scrollToRef: () => void;
}

const styles = StyleSheet.create({
  appButtonContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  button: {
    alignItems: 'center',
    backgroundColor: '#02969e',
    borderRadius: 20,
    marginTop: 15,
    paddingHorizontal: 20,
    paddingVertical: 12,
  },
  buttonText: {
    color: '#fff',
    fontSize: 18,
    fontWeight: '600',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  clientLogo: {
    height: 65,
    resizeMode: 'contain',
    width: 300,
  },
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    width: '100%',
  },
  heading: {
    fontFamily: 'eurostile',
    textAlign: 'center',
  },
  pageMargin: {
    alignSelf: 'center',
    maxWidth: 1200,
    width: '80%',
  },
  paragraph: {
    fontFamily: 'nimbus',
    paddingHorizontal: 15,
    marginVertical: 10,
    textAlign: 'center',
  },
  rowChild: {
    alignItems: 'center',
    flex: isApp ? 0 : 1,
    justifyContent: 'center',
    minWidth: 300,
  },
  rowContainer: {
    alignSelf: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    maxWidth: 1200,
    width: '80%',
  },
});

export default function ContentSectionContainer(
  {
    contentSection, navigation, renderButton, scrollToRef,
  } : Props,
) {
  const { width } = useWindowDimensions();

  const { android_app_link, ios_app_link, product_brochure } = useAppSelector(({ client }) => client);

  const {
    background_colour, background_image,
    font_colour, heading_size, layout_style, padding, paragraph_1,
    paragraph_2, paragraph_3, position, subheading, subheading_colour,
    title, video, web_only,
  } = contentSection;

  const hasNoText = title === '' && paragraph_1 === '' && paragraph_2 === '' && paragraph_3 === '';
  const navigateTo = (name : string) => () => { navigation.navigate(name); };
  const minHeight = () => {
    if (background_image && width > 700) {
      return 500;
    }
    if (width < 700 && !isApp && !background_image) {
      return 'auto';
    }
    if (width < 700 && background_image) {
      return 215;
    }
    if (width < 700 && !isApp) {
      return 300;
    }
    if (isApp && background_image) {
      return 300;
    }
    return 'auto';
  };

  const imageSizing = title.toLowerCase() === 'download our app' ? 'contain' : 'cover';
  const defaultPadding = padding || 150;
  const paddingVertical = background_image && width > 700 ? 10 : defaultPadding;

  const renderParagraphs = () => (
    [paragraph_1, paragraph_2, paragraph_3].map((p, i) => (
      p ? (
        <Text key={i} style={[styles.paragraph, { color: font_colour || '#000000', fontSize: 24 }]}>{ p }</Text>
      ) : null
    ))
  );

  const renderExtras = () => {
    if (title.toLowerCase() === 'download our brochure' && product_brochure) {
      return (
        <TouchableOpacity onPress={() => Linking.openURL(product_brochure)}>
          <View style={styles.button}>
            <Text style={styles.buttonText}>Brochure Design 2022</Text>
          </View>
        </TouchableOpacity>
      );
    }
    if (title.toLowerCase() === 'download our app') {
      return (
        <View style={styles.appButtonContainer}>
          <View>
            <TouchableOpacity onPress={() => Linking.openURL(ios_app_link)}>
              <Image style={{ resizeMode: 'contain', width: 150, height: 100 }} source={require('../assets/app-store-logo.png')} />
            </TouchableOpacity>
          </View>
          <View>
            <TouchableOpacity onPress={() => Linking.openURL(android_app_link)}>
              <Image style={{ resizeMode: 'contain', width: 150, height: 100 }} source={require('../assets/google-play-logo.png')} />
            </TouchableOpacity>
          </View>
        </View>
      );
    }
  };

  const renderText = () => (
    <>
      {
        title !== '' ? (
          <Text style={[styles.heading, { color: font_colour || '#000000', fontSize: heading_size || 28, marginBottom: 10 }]}>{ title }</Text>
        ) : <></>
      }
      {
        subheading ? (
          <Text style={[styles.heading, { color: subheading_colour || '#000000', fontSize: ((heading_size || 28) * 0.7), marginBottom: 10 }]}>{ subheading }</Text>
        ) : <></>
      }
      {
        renderParagraphs()
      }
      {
        renderExtras()
      }
    </>
  );

  const renderImage = () => (
    <>
      { background_image
        ? <Image style={{ resizeMode: imageSizing, width: '100%', height: isApp ? 200 : 400, marginVertical: width < 764 ? 20 : 0 }} source={{ uri: background_image }} />
        : <></> }
    </>
  );

  const renderContentSection = () => {
    if (web_only && isApp) {
      return (
        <>
        </>
      );
    }
    if (video) {
      return (
        <HomeScreenVideo
          homeTitle={title}
          paragraph_1={paragraph_1}
          paragraph_2={paragraph_2}
          paragraph_3={paragraph_3}
          font_colour={font_colour}
          heading_size={heading_size}
          navigateTo={navigateTo}
          position={position}
          renderButton={renderButton}
          scrollToRef={scrollToRef}
          videoUrl={video}
        />
      );
    }
    if (layout_style === 2 || layout_style === 3) {
      return (
        <View style={{ justifyContent: 'center', paddingVertical: padding, backgroundColor: `${background_colour}` || '', width: '100%' }}>
          <View style={styles.rowContainer}>
            <View style={styles.rowChild}>
              { layout_style === 2 || width < 764 ? renderImage() : renderText() }
            </View>
            <View style={styles.rowChild}>
              { layout_style === 2 || width < 764 ? renderText() : renderImage() }
            </View>
          </View>
        </View>
      );
    }
    return (
      <View style={[styles.container, { backgroundColor: `${background_colour}` || '', paddingVertical: isApp ? 8 : paddingVertical, minHeight: hasNoText && !background_image ? 'auto' : minHeight(), maxWidth: hasNoText && background_image ? 1200 : 'auto', alignSelf: 'center' }]}>
        { background_image
          ? <Image style={{ resizeMode: !hasNoText || (hasNoText && background_image) ? 'cover' : 'contain', flex: 1, width: '100%', height: '100%', position: 'absolute' }} source={{ uri: background_image }} />
          : <></> }
        <View style={styles.pageMargin}>
          {
            renderText()
          }
        </View>
      </View>
    );
  };

  return (
    <>
      { renderContentSection() }
    </>
  );
}
