import { SelectedModule } from '../types/index';

export const allDrawersSelectedForModule = (
  selectedModule: SelectedModule,
) => selectedModule.drawers === Object.keys(selectedModule.selectedDrawers).length;

export const hasDrawers = (selectedModule: SelectedModule) : boolean => !!selectedModule.drawers;

export const noOfDrawersSelected = (selecetedModule: SelectedModule): number => (
  Object.keys(selecetedModule.selectedDrawers).length
);

export const allOptionsSelected = (selecetedModule: SelectedModule): boolean => (
  allDrawersSelectedForModule(selecetedModule)
    && !!selecetedModule.selectedFinish
    && (!!selecetedModule.selectedLining || !selecetedModule.display_lining_label)
);
