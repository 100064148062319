import React, { useRef } from 'react';
import {
  Platform, StyleSheet,
  Text, TouchableOpacity, useWindowDimensions, View,
} from 'react-native';
import { Video } from 'expo-av';
import AnimatedScrollButton from '../components/AnimatedScrollButton';

const maxHeight = Platform.OS === 'web' ? 1000 : 'auto';

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    maxHeight,
    overflow: 'hidden',
  },
  paragraph: {
    fontFamily: 'nimbus',
    fontSize: 24,
    textAlign: 'center',
  },
  scrollButton: {
    alignItems: 'center',
    alignSelf: 'center',
    bottom: 10,
    height: 40,
    position: 'absolute',
    width: '100%',
  },
  video: {
    position: 'absolute',
    width: Platform.OS === 'web' ? 'unset' : '100%',
    flex: Platform.OS === 'web' ? 1 : 0,
  },
  videoOverlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    flex: Platform.OS === 'web' ? 1 : 0,
    width: '100%',
  },
});

interface Props {
  font_colour: string;
  heading_size: string;
  homeTitle: string;
  navigateTo: () => void;
  paragraph_1: string;
  paragraph_2: string;
  paragraph_3: string;
  position: number;
  renderButton: () => void | null;
  scrollToRef: () => void;
  videoUrl: string;
}

const HomeScreenVideo = ({
  font_colour, heading_size, homeTitle,
  paragraph_1, paragraph_2, paragraph_3, position,
  renderButton, scrollToRef, videoUrl,
} : Props) => {
  const video = useRef(null);
  const { height, width } = useWindowDimensions();
  const hasBodyText = !!paragraph_1 || !!paragraph_2 || !!paragraph_3;
  const heightAdjustment = width > 500 ? 120 : 80;

  const titlePadding = () => {
    if (width > 700 && hasBodyText) {
      return 20;
    }
    if (width > 700 && !hasBodyText) {
      if (height > 700) return 100;
      return 20;
    }
    return 20;
  };
  const headingPadding = () => {
    if (width > 700) {
      return '45%';
    }
    if (height < 780) {
      return '30%';
    }
    return '35%';
  };

  return (
    <View style={[styles.container, { height: height - heightAdjustment }]}>
      { videoUrl ? (
        <Video
          ref={video}
          style={[styles.video, { height: Platform.OS === 'web' ? 'unset' : height - heightAdjustment }]}
          source={{ uri: videoUrl }}
          resizeMode="cover"
          isMuted
          isLooping
          shouldPlay
        />
      ) : null}
      <View style={[styles.videoOverlay, { height: Platform.OS === 'web' ? 'unset' : height - heightAdjustment }]} />
      <View style={{
        alignSelf: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: headingPadding(),
        width: '80%',
      }}
      >
        <Text style={{
          fontFamily: 'eurostile',
          fontSize: heading_size,
          color: font_colour || '#fff',
          marginBottom: titlePadding(),
          textAlign: 'center',
        }}
        >
          { homeTitle }
        </Text>
        {
          paragraph_1 ? (
            <Text style={[styles.paragraph, { color: font_colour || '#fff' }]}>{ paragraph_1 }</Text>
          ) : <></>
        }
        {
          paragraph_2 ? (
            <Text style={[styles.paragraph, { color: font_colour || '#fff' }]}>{ paragraph_2 }</Text>
          ) : <></>
        }
        {
          paragraph_3 ? (
            <Text style={[styles.paragraph, { color: font_colour || '#fff' }]}>{ paragraph_3 }</Text>
          ) : <></>
        }
        {
          renderButton ? renderButton() : <></>
        }
      </View>
      {
        scrollToRef && position === 1 ? (
          <TouchableOpacity style={styles.scrollButton} onPress={() => scrollToRef()}>
            <AnimatedScrollButton />
          </TouchableOpacity>
        ) : (
          <></>
        )
      }
    </View>
  );
};

export default HomeScreenVideo;
