import React, { useState, useEffect } from 'react';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import {
  StyleSheet, ScrollView, Text, useWindowDimensions, View,
} from 'react-native';
import { useAppSelector } from '../hooks/store';
import { RootStackParamList } from '../types/routes';
import { SelectedModule } from '../types/';
import AppContainer from '../components/AppContainer';
import BackButton from '../components/BackButton';
import ProductDetailsHeader from '../components/ProductDetailsHeader';
import submitDetails from '../helpers/submitDetails';
import NavigationBar from '../components/NavigationBar';
import getClientCode from '../helpers/getClientCode';
import ProductPreview from '../components/ProductPreview';
import ModuleSlider from '../components/ModuleSlider';
import ConditionalButton from '../components/ConditionalButton';
import HeaderSection from '../components/HeaderSection';
import ScrollViewHeader from '../components/ScrollViewHeader';

type Props = NativeStackScreenProps<RootStackParamList, 'Summary'>

const styles = StyleSheet.create({
  heading: {
    fontFamily: 'eurostile',
    fontSize: 24,
    marginVertical: 15,
    textAlign: 'left',
  },
});

const KeyValueText = (
  { keyText, valueText } : { keyText: string, valueText: string },
) => (
  <View>
    <View style={{ flexDirection: 'row', flexWrap: 'nowrap', marginBottom: 5 }}>
      <Text style={{ fontWeight: '700', minWidth: '40%' }}>{ keyText }</Text>
    </View>
    <View style={{ marginBottom: 10 }}>
      <Text>{ valueText }</Text>
    </View>
  </View>
);

export default function SummaryScreen({ navigation } : Props) {
  const [idempotencyKey, setIdempotencyKey] = useState<string | null>(null);
  const [submitted, setSubmitted] = useState(false);
  const { width } = useWindowDimensions();
  const product = useAppSelector(
    ({ product: productStore }) => productStore.product,
  );
  const grade = useAppSelector(
    ({ product: productStore }) => productStore.grade,
  );
  const size = useAppSelector(
    ({ product: productStore }) => productStore.size,
  );

  const modules = useAppSelector(
    ({ product: productStore }) => productStore.selectedModules,
  );

  const selectedSafeColour = useAppSelector(
    ({ product: productStore }) => productStore.safeColour,
  );

  const { detailing } = useAppSelector(
    ({ product: productStore }) => productStore,
  );

  const customer = useAppSelector(({ customer: customerSlice }) => customerSlice);
  useEffect(() => {
    // TODO: find better method?
    const randomUniqueKey = (performance.now().toString(36) + Math.random().toString(36)).replace(/\./g, '');
    setIdempotencyKey(randomUniqueKey);
  }, []);

  const onSubmit = async () => {
    if (!product || !grade || !size || !idempotencyKey) return;
    setSubmitted(true);
    const selectedModules = Object.values(modules).map((m : SelectedModule) => {
      const drawers = Object.values(m.selectedDrawers).map((drawer) => (
        {
          id: drawer.id,
          name: drawer.name,
          position: drawer.position,
        }
      ));
      return {
        id: m.id,
        module_name: m.name,
        position: m.position,
        drawer_lining_id: m.selectedLining?.id,
        drawer_lining_name: m.selectedLining?.colour_code ? `${m.selectedLining?.name} ${m.selectedLining?.colour_code}` : m.selectedLining?.name,
        module_finish_id: m.selectedFinish?.id,
        module_finish_name: m.selectedFinish?.colour_code ? `${m.selectedFinish?.name} ${m.selectedFinish?.colour_code}` : m.selectedFinish?.name,
        drawers,
      };
    });
    const data = {
      name: customer.name,
      address_line_1: customer.address_line_1,
      postcode: customer.postcode,
      town_city: customer.town_city,
      company_name: customer.company_name,
      email: customer.email,
      phone: customer.phone,
      product_id: product.id,
      product_name: product.name,
      grade_id: grade.id,
      grade_name: grade.name,
      size_id: size.id,
      size_name: size.name,
      safe_colour_id: selectedSafeColour?.id,
      detailing_id: detailing?.id,
      modules: selectedModules,
      idempotencyKey,
      client_code: getClientCode(),
    };
    await submitDetails(data);
    navigation.navigate('Design Complete');
  };

  return (
    <>
      <HeaderSection navigation={navigation} />
      <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
        <AppContainer>
          <View style={{ flex: 1 }}>
            <ScrollViewHeader title="Product Detail" />
            <ScrollView style={{ marginTop: 20, marginBottom: 100 }}>
              <View style={{ display: 'flex', width: '100%', flexDirection: width > 700 ? 'row' : 'column' }}>
                <View style={{ alignItems: 'center', width: width > 700 ? '50%' : '100%' }}>
                  <View style={{ width: 420 }}>
                    <ProductPreview />
                  </View>
                </View>
                <View style={{ alignItems: 'center', width: width > 700 ? '50%' : '100%' }}>
                  <Text style={styles.heading}>Your details</Text>
                  <View style={{ width: '80%' }}>
                    <KeyValueText keyText="Name" valueText={customer.name} />
                    <KeyValueText keyText="Street / House" valueText={customer.address_line_1} />
                    <KeyValueText keyText="Town / City" valueText={customer.town_city} />
                    <KeyValueText keyText="Postcode" valueText={customer.postcode} />
                    <KeyValueText keyText="Company name" valueText={customer.company_name} />
                    <KeyValueText keyText="Email" valueText={customer.email} />
                    <KeyValueText keyText="Phone" valueText={customer.phone} />
                  </View>
                </View>
              </View>
              <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
                <View style={{ minWidth: 300, width: '95%' }}>
                  <View>
                    <ProductDetailsHeader
                      detailing={detailing}
                      grade={grade}
                      product={product}
                      safeColour={selectedSafeColour}
                      sizeName={size.name}
                    />
                  </View>
                  {
                    product && size ? (
                      <View>
                        <ModuleSlider
                          modules={Object.values(modules)}
                          product={product}
                        />
                      </View>
                    ) : null
                  }
                </View>
              </View>
            </ScrollView>
          </View>
        </AppContainer>
      </ScrollView>
      <NavigationBar>
        <BackButton />
        {
          submitted
            ? <></>
            : <ConditionalButton condition onPress={onSubmit} title="Send" />
        }
      </NavigationBar>
    </>
  );
}
