import React, { useEffect, useRef, useState } from 'react';
import { Animated, Easing, Image } from 'react-native';

const AnimatedScrollButton = () => {
  const animatedValue = useRef(new Animated.Value(0)).current;
  const [isTop, setIsTop] = useState(true);

  const translateY = animatedValue.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 20],
    extrapolate: 'clamp',
  });

  const startAnimation = (toValue: number) => {
    Animated.timing(animatedValue, {
      toValue,
      duration: 1000,
      easing: Easing.linear,
      useNativeDriver: true,
    }).start(() => {
      setIsTop(!isTop);
    });
  };

  useEffect(() => {
    startAnimation(isTop ? 1 : 0);
  }, [isTop]);

  return (
    <Animated.View style={{ transform: [{ translateY }] }}>
      <Image
        style={{ width: 12, height: 20, opacity: 0.8 }}
        source={require('../assets/angle-down.png')}
      />
    </Animated.View>
  );
};

export default AnimatedScrollButton;
