import React, { useEffect, useState } from 'react';
import { Platform, ScrollView, StyleSheet, Text, useWindowDimensions, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { TouchableOpacity } from "react-native";
import { useAppDispatch, useAppSelector } from '../hooks/store';
import { RootStackParamList } from '../types/routes';
import Footer from '../components/Footer';
import { ContentSection, Product } from '../types/index';
import fetchRangeData from '../helpers/getRangeData';
import ContentSectionContainer from '../components/ContentSectionContainer';
import PresetSelect from '../components/PresetSelect';
import HomeNavigation from '../components/HomeNavigation';
import { updateProduct } from '../store/productSlice';

type Props = NativeStackScreenProps<RootStackParamList, 'Range'>

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    backgroundColor: '#02969e',
    borderRadius: 20,
    paddingHorizontal: 20,
    paddingVertical: 12,
  },
  buttonText: {
    color: '#fff',
    fontSize: 18,
    fontWeight: '600',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
});

export default function RangeScreen({ route, navigation } : Props) {
  const [mainScroller, setMainScroller] = useState(null);
  const { productId } = route.params;
  const [contentSections, setContentSections] = useState([]);
  const { products } = useAppSelector(({ allProducts }) => allProducts);
  const [presets, setPresets] = useState([]);
  const client = useAppSelector(({ client }) => client);
  const { footer_header_background_colour, logoUrl } = client;
  const dispatch = useAppDispatch();
  const { height, width } = useWindowDimensions();
  const isApp = Platform.OS !== 'web';
  const heightAdjustment = width > 500 ? 120 : 80;

  const scrollToRef = () => {
    if (mainScroller) {
      mainScroller.scrollTo({
        y: height < 1000 ? (height - heightAdjustment) - 85 : 1000,
        animated: true,
      });
    }
  };

  useEffect(() => {
    fetchRangeData(productId)
      .then((json: any) => {
        setContentSections(json.content_sections);
        setPresets(json.presets);
      })
      .catch((error) => console.error(error));
  }, []);

  const product = products.find((p) => p.id === Number(productId));

  const next = () => {
    if (product) {
      dispatch(updateProduct(product));
      navigation.navigate('Product');
    }
  };

  const renderButton = () => (
    <TouchableOpacity onPress={() => next()}>
      <View style={[styles.button, { marginTop: height > 650 ? 80 : 20 }]}>
        <Text style={styles.buttonText}>
          Design your {product.name}
        </Text>
      </View>
    </TouchableOpacity>
  );

  const renderContentSection = (item: ContentSection) => (
    item.title === 'SHOW RANGE' ? (
      <View key={item.id.toString()}>
        <PresetSelect navigation={navigation} presets={presets} products={products} />
      </View>
    ) : (
      <ContentSectionContainer
        key={item.id.toString()}
        contentSection={item}
        navigation={navigation}
        renderButton={renderButton}
        scrollToRef={scrollToRef}
      />
    )
  );

  const renderRangeScreen = () => (
    <>
      <HomeNavigation
        footer_header_background_colour={footer_header_background_colour}
        navigation={navigation}
        logoUrl={logoUrl}
      />
      <ScrollView
        ref={(mainScroller) => {
          if (mainScroller !== null) {
            setMainScroller(mainScroller);
          }
        }}
      >
        {
          contentSections.map((c) => (
            renderContentSection(c)
          ))
        }
        <Footer />
      </ScrollView>
    </>
  )

  return (
    isApp ? (
      <SafeAreaView>
        { renderRangeScreen() }
      </SafeAreaView>
    ) : renderRangeScreen()
  );
}
