import React from 'react';
import {
  View, Image, Pressable, Platform, Text,
} from 'react-native';
import { DrawerLining, ModuleFinish } from '../types/index';
import isApp from '../helpers/isApp'

type Props = {
  colour: DrawerLining | ModuleFinish;
  onPress(): void;
  active: boolean;
};

const logo = require('../assets/icon.png');

export default function ColourOption({
  colour, onPress, active,
} : Props) {
  return (
    <Pressable
      style={[
        {
          padding: 10, margin: isApp ? 5 : 10, minWidth: 100, flexGrow: 0, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', borderWidth: 2, borderColor: 'transparent',
        },
      ]}
      onPress={onPress}
    >
      <View style={{ borderRadius: 15, width: isApp ? 140 : 180, height: isApp ? 140 : 180, overflow: 'hidden' }}>
        <Image
          style={{ width: '100%', height: '100%' }}
          source={colour.image ? { uri: colour.image } : logo}
          resizeMode="cover"
        />
      </View>
      <View
        style={[
          {
            alignItems: 'center', flexDirection: isApp ? 'column' : 'row', width: isApp ? 125 : 160,position: 'absolute', padding: 5, paddingBottom: 0, bottom: 30, backgroundColor: active ? '#02969e' : '#fff',
          },
        ]}
      >
        {
          colour?.colour_code ? (
            <Text style={{ color: active ? '#fff' : '#000', fontFamily: 'nimbus' }}>{colour?.colour_code}</Text>
          ) : <></>
        }
        <Text style={{ color: active ? '#fff' : '#000', fontFamily: 'nimbus', marginLeft: isApp ? 0 : 10 }}>{colour?.name}</Text>
      </View>
    </Pressable>
  );
}
