import React, { useState, useEffect } from 'react';
import {
  Text, View, useWindowDimensions, FlatList, ScrollView,
} from 'react-native';
import { Button as EButton, Icon } from 'react-native-elements';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import BackButton from '../../components/BackButton';
import { useAppSelector, useAppDispatch } from '../../hooks/store';
import { ProductStackParamList } from '../../types/routes'
import ConditionalButton from '../../components/ConditionalButton';
import { SelectedModule } from '../../types/index';
import TabOptions from './TabOptions';
import { updateSelectedLining, updateSelectedFinish } from '../../store/productSlice';
import { allOptionsSelected } from '../../helpers/module_helpers';
import NavigationBar from '../../components/NavigationBar';
import AppContainer from '../../components/AppContainer';
import ModuleBox from '../../components/ModuleBox';
import Modal from '../../components/Modal';
import HeaderSection from '../../components/HeaderSection';

type Props = NativeStackScreenProps<ProductStackParamList, 'Select Drawers'>

export default function OptionsSelectScreen({ navigation } : Props) {
  const dispatch = useAppDispatch();
  const selectedGrade = useAppSelector(({ product }) => product.grade);
  const selectedSize = useAppSelector(({ product }) => product.size);
  const selectedSafeColour = useAppSelector(({ product }) => product.safeColour);
  const selectedDetailing = useAppSelector(({ product }) => product.detailing);
  const selectedModules = useAppSelector(({ product }) => product.selectedModules);
  const [currentSelection, setCurrentSelection] = useState<SelectedModule | null>(
    selectedModules[1],
  );
  const [modalVisibility, setModalVisibility] = useState<boolean>(true);
  const { width } = useWindowDimensions();

  const modules = Object.values(selectedModules);

  const displayModal = () => !!(currentSelection && modalVisibility);
  const safeColourSelected = () => (
    selectedSize?.safe_colours?.length ? !!selectedSafeColour : true
  );

  const detailingSelected = () => (
    selectedSize?.detailings?.length ? !!selectedDetailing : true
  );

  // if some modules have a lining and
  // linings cannot be customised on a per module basis then
  // update lining for all modules
  useEffect(() => {
    if (selectedGrade?.module_linings_customisable) return;
    const module = modules.find((m) => !!m.selectedLining);
    const lining = module?.selectedLining;
    if (lining) {
      modules.forEach((m) => {
        const data = { selectedModule: m, lining };
        dispatch(updateSelectedLining(data));
      });
    }
  }, []);

  const onModulePress = (value: SelectedModule) => {
    setCurrentSelection(value);
    setModalVisibility(true);
  };

  const renderModuleBoxContent = (item: SelectedModule) => (
    <>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <Text style={{ fontWeight: '700', width: '100%', textAlign: 'center' }}>
          {item.name}
        </Text>
        {item.selectedFinish && item.selectedLining ? <Icon name="check" color="green" /> : null}
      </View>
      <Text>
        Module Finish:&nbsp;
        {item.selectedFinish?.name || ''}
      </Text>
      <Text>
        Drawer Lining:&nbsp;
        {item.selectedLining?.name || ''}
      </Text>
    </>
  );

  // if some modules have a finish and
  // finishes cannot be customised on a per module basis then
  // update finishes for all modules
  useEffect(() => {
    if (!selectedGrade?.module_finishes_customisable) {
      const module = modules.find((m) => !!m.selectedFinish);
      const finish = module?.selectedFinish;
      if (finish) {
        modules.forEach((m) => {
          const data = { selectedModule: m, finish };
          dispatch(updateSelectedFinish(data));
        });
      }
    }
  }, []);

  const canContinue = () => {
    const selected = Object.values(selectedModules);
    return selected.every(
      (item : SelectedModule) => (
        allOptionsSelected(item)
      ) && safeColourSelected() && detailingSelected(),
    );
  };

  const onNext = () => {
    if (canContinue()) navigation.navigate('Customer Details');
  };

  // We don't need to support this, can be removed
  const customisablePerModuleView = () => (
    <View
      style={{
        flexDirection: 'row', flexGrow: 1, width: '100%',
      }}
    >
      <View style={{ width: width > 700 ? '50%' : '100%' }}>
        <FlatList
          style={{ flexGrow: 0 }}
          contentContainerStyle={{ alignItems: 'center', borderWidth: 1, alignSelf: 'center' }}
          data={Object.values(selectedModules)}
          keyExtractor={(sm : SelectedModule) => sm.position.toString()}
          renderItem={({ item }) => (
            <ModuleBox
              active={item.position === currentSelection?.position}
              onPress={() => { onModulePress(item); }}
            >
              { renderModuleBoxContent(item) }
            </ModuleBox>
          )}
        />
      </View>
      { currentSelection && width <= 700 ? (
        <Modal
          display={displayModal()}
        >
          <ScrollView>
            <View style={{ marginBottom: 10 }}>
              <TabOptions
                currentSelection={selectedModules[currentSelection.position]}
              />
            </View>
          </ScrollView>
          <EButton title="Done" onPress={() => setModalVisibility(false)} />
        </Modal>
      ) : null}
      {
        currentSelection && width > 700 ? (
          <View style={{ width: '50%', justifyContent: 'flex-start' }}>
            <TabOptions
              currentSelection={selectedModules[currentSelection.position]}
            />
          </View>
        ) : null
      }
    </View>
  );

  return (
    <>
      <HeaderSection navigation={navigation} />
      <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
        <AppContainer>
          <View style={{ alignItems: 'center', flex: 1, marginBottom: 60 }}>
            {
              selectedGrade?.module_finishes_customisable
              && selectedGrade.module_linings_customisable
                ? customisablePerModuleView() : (
                  <View style={{ width: '100%', justifyContent: 'flex-start', flex: 1 }}>
                    <TabOptions
                      currentSelection={selectedModules[currentSelection?.position || 1]}
                    />
                  </View>
                )
            }
          </View>
        </AppContainer>
      </ScrollView>
      <NavigationBar>
        <BackButton />
        <ConditionalButton condition={canContinue()} onPress={onNext} title="Next" />
      </NavigationBar>
    </>
  );
}
