import React from 'react';
import { useAppDispatch } from '../hooks/store';
import { updateClientData } from '../store/clientSlice';
import fetchClientData from '../helpers/fetchClientData';
import getClientCode from '../helpers/getClientCode';

const ClientData = () => {
  const dispatch = useAppDispatch();
  const clientCode = getClientCode();
  fetchClientData(clientCode, true)
    .then((data) => {
      const client = { ...data, code: clientCode };
      dispatch(updateClientData(client));
    });
  return null;
};

export default ClientData;
