import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React from 'react';
import {
  ScrollView, View,
} from 'react-native';
import { useAppSelector, useAppDispatch } from '../../hooks/store';
import { updateGrade } from '../../store/productSlice';
import BackButton from '../../components/BackButton';
import GradeOption from '../../components/GradeOption';
import { ProductStackParamList } from '../../types/routes';
import { Grade, Product } from '../../types/index';
import ConditionalButton from '../../components/ConditionalButton';
import AppContainer from '../../components/AppContainer';
import NavigationBar from '../../components/NavigationBar';
import HeaderSection from '../../components/HeaderSection';
import ScrollViewHeader from '../../components/ScrollViewHeader';

type Props = NativeStackScreenProps<ProductStackParamList, 'Select Grade'>

const next = (gradeSelected: boolean, navigation: Props['navigation']) => {
  if (!gradeSelected) return;
  navigation.navigate('Select Size');
};

// returns true if a grade is truthy and belongs to product
const gradeSelected = (product: Product | null, grade: Grade | null) => (
  !!(grade && product?.grades.find((g) => g.id === grade.id))
);

const isActive = (item: Grade, grade: Grade | null) => (
  !!(grade && grade.id === item.id)
);

export default function GradeSelectScreen({ navigation } : Props) {
  const dispatch = useAppDispatch();
  const product = useAppSelector(({ product: productStore }) => productStore.product);
  const grade = useAppSelector(({ product: productStore }) => productStore.grade);

  const grades = product?.grades;

  return (
    <>
      <HeaderSection navigation={navigation} />
      <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
        <AppContainer>
          <View style={{ flex: 1, alignItems: 'center', justifyContent: 'flex-start' }}>
            <ScrollViewHeader title="Choose a Security Level" />
            <ScrollView
              horizontal
              style={{ maxWidth: '100%', flexGrow: 0 }}
              contentContainerStyle={{ flexGrow: 1, justifyContent: 'space-evenly', paddingVertical: 20 }}
            >
              {
                grades?.map((item) => (
                  <GradeOption
                    key={item.id.toString()}
                    grade={item}
                    onPress={() => { dispatch(updateGrade(item)); }}
                    active={isActive(item, grade)}
                  />
                ))
              }
            </ScrollView>
          </View>
        </AppContainer>
      </ScrollView>
      <NavigationBar>
        <BackButton />
        <ConditionalButton condition={gradeSelected(product, grade)} onPress={() => next(gradeSelected(product, grade), navigation)} title="Next" />
      </NavigationBar>
    </>
  );
}
