import React from 'react';
import {
  View, ScrollView,
} from 'react-native';
import { useAppSelector, useAppDispatch } from '../../hooks/store';
import { updateSelectedLining } from '../../store/productSlice';
import { DrawerLining, SelectedModule } from '../../types';
import ColourOption from '../../components/ColourOption';

type Props = {
  currentSelection: SelectedModule;
}
export default function LiningSelectionScreen({ currentSelection } : Props) {
  const dispatch = useAppDispatch();
  const selectedGrade = useAppSelector(({ product }) => product.grade);
  const selectedModules = useAppSelector(({ product }) => product.selectedModules);
  const drawerLinings = useAppSelector(({ product }) => product.size?.drawer_linings);

  const onLiningSelect = (lining: DrawerLining) => {
    if (!currentSelection) return;
    // Only update lining for selected module
    if (selectedGrade?.module_linings_customisable) {
      const data = { selectedModule: currentSelection, lining };
      dispatch(updateSelectedLining(data));
      return;
    }
    // Update lining for all modules
    Object.values(selectedModules).forEach((m) => {
      const data = { selectedModule: m, lining };
      dispatch(updateSelectedLining(data));
    });
  };

  const selected = (lining: DrawerLining): boolean => (
    selectedModules[currentSelection.position].selectedLining?.id === lining.id
  );

  return (
    <ScrollView>
      <View
        style={{
          flex: 1, flexDirection: 'row', flexWrap: 'wrap', width: '100%', flexGrow: 1, justifyContent: 'center',
        }}
      >
        { currentSelection
          ? (
            drawerLinings?.map((lining) => (
              <ColourOption
                key={lining.id}
                colour={lining}
                onPress={() => { onLiningSelect(lining); }}
                active={selected(lining)}
              />
            ))
          )
          : null}
      </View>
    </ScrollView>
  );
}
