import React, { useState } from 'react';
import {
  Image, ScrollView, StyleSheet, Text, TouchableOpacity, View,
} from 'react-native';
import Icon from 'react-native-vector-icons/AntDesign';

type Props = {
  modules: any;
  product: any,
};

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    backgroundColor: '#3997f3',
    borderRadius: 2,
    paddingHorizontal: 15,
    paddingVertical: 10,
  },
  buttonText: {
    color: '#fff',
    fontWeight: '600',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  flatList: {
    flexDirection: 'row',
    flex: 1,
    paddingBottom: 20,
    width: '100%',
  },
  flatListContentContainer: {
    paddingHorizontal: 15,
  },
  moduleTile: {
    backgroundColor: '#f5f5f5',
    borderRadius: 20,
    marginHorizontal: 15,
    marginTop: 40,
    marginBottom: 10,
    minHeight: 381,
    paddingHorizontal: 45,
    paddingVertical: 30,
    width: 350,
  },
  moduleTitle: {
    fontFamily: 'nimbus',
    fontWeight: 'bold',
    paddingTop: 10,
    textAlign: 'center',
  },
});

const ModuleSlider = ({ modules, product } : Props) => {
  const [drawerDisplay, setDrawerDisplay] = useState<number>(0);

  const updateDrawerDisplay = (position : number) => {
    if (drawerDisplay === position) return setDrawerDisplay(0);
    return setDrawerDisplay(position);
  };

  const renderModuleTile = (module) => {
    const selectedDrawers = Object.values(module.selectedDrawers);

    return (
      <View style={styles.moduleTile}>
        <Text>{`Module ${module.position}`}</Text>
        <Text style={{ fontSize: 16, fontWeight: 'bold', marginTop: 10 }}>{module.name}</Text>
        <View style={{ alignItems: 'center', display: 'flex', width: 180, height: 90, marginVertical: 20 }}>
          <Image
            style={{ width: '100%', height: '100%' }}
            source={{ uri: module.image }}
            resizeMode="contain"
          />
        </View>
        <Text>{product?.finish_label ? product?.finish_label : 'Outside Faux Leather'}</Text>
        <Text style={{ fontSize: 14, fontWeight: 'bold' }}>{module.selectedFinish.name}</Text>
        {
          module.display_lining_label ? (
            <>
              <Text style={{ marginTop: 10 }}>{product?.lining_label ? product?.lining_label : 'Inside Velvet'}</Text>
              <Text style={{ fontSize: 14, fontWeight: 'bold', marginBottom: 10 }}>{module.selectedLining.name}</Text>
            </>
          ) : <></>
        }
        { selectedDrawers.length > 0
        && (
        <View>
          <TouchableOpacity onPress={() => updateDrawerDisplay(module.position)}>
            <Text>Drawer Layout</Text>
            <View style={{ marginVertical: 10 }}>
              <Icon name={drawerDisplay === module.position ? 'up' : 'down'} color="#02969e" size={25} />
            </View>
          </TouchableOpacity>
          {
            drawerDisplay === module.position && selectedDrawers?.map((item: any) => (
              <View key={item.position}>
                <View>
                  <Text style={{ fontSize: 14, fontWeight: 'bold' }}>
                    { item.name }
                  </Text>
                  <View style={{ height: 90, width: 220 }}>
                    <Image
                      style={{ width: '100%', height: '100%', resizeMode: 'contain' }}
                      source={{ uri: item.image }}
                    />
                  </View>
                </View>
              </View>
            ))
          }
        </View>
        )}
      </View>
    );
  };

  return (
    <ScrollView horizontal style={styles.flatList}>
      {
        modules.map((module : any) => (
          <View key={module.position}>
            {
              renderModuleTile(module)
            }
          </View>
        ))
      }
    </ScrollView>
  );
};

export default ModuleSlider;
