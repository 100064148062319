import React from 'react';
import {
  StyleSheet, Text, TouchableOpacity, useWindowDimensions, View,
} from 'react-native';

interface Props {
  condition: boolean;
  onPress(): void;
  title: string;
}

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    backgroundColor: '#02969e',
    borderRadius: 12,
    paddingVertical: 15,
  },
  buttonText: {
    color: '#fff',
    fontSize: 18,
    fontWeight: '600',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  inactive: {
    opacity: 0.5,
  },
});

export default function ConditionalButton({ condition, onPress, title } : Props) {
  const { width } = useWindowDimensions();
  if (condition) {
    return (
      <TouchableOpacity onPress={() => onPress()}>
        <View style={[styles.button, { width: width > 400 ? 150 : 120 }]}>
          <Text style={styles.buttonText}>{title}</Text>
        </View>
      </TouchableOpacity>
    );
  }
  return (
    <View style={[styles.button, styles.inactive, { width: width > 400 ? 150 : 120 }]}>
      <Text style={styles.buttonText}>{title}</Text>
    </View>
  );
}
