import React from 'react';
import {
  View,
} from 'react-native';

type Props = {
  display: boolean;
  children: any;
}

export default function Modal({
  display, children,
} : Props) {
  return display ? (
    <View
      style={{
        flexDirection: 'column',
        position: 'absolute',
        height: '100%',
        backgroundColor: '#fff',
        width: '100%',
        display: 'flex',
        flexGrow: 1,
        maxWidth: 1200,
        padding: 16,
        zIndex: 100,
      }}
    >
      { display ? children : null }
    </View>
  ) : null;
}
