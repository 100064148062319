import React from 'react';
import { View, ScrollView } from 'react-native';
import { useAppSelector, useAppDispatch } from '../../hooks/store';
import { updateDetailing } from '../../store/productSlice';
import { Detailing } from '../../types';
import ColourOption from '../../components/ColourOption';

export default function DetailingSelectionScreen() {
  const dispatch = useAppDispatch();
  const selectedDetailing = useAppSelector(({ product }) => product.detailing);
  const detailings = useAppSelector(({ product }) => product.size?.detailings);

  const onDetailingSelect = (detailing: Detailing) => {
    dispatch(updateDetailing(detailing));
  };

  const selected = (detailing: Detailing): boolean => (
    selectedDetailing?.id === detailing.id
  );

  return (
    <ScrollView>
      <View
        style={{
          flexDirection: 'row', flexWrap: 'wrap', flex: 1, justifyContent: 'center',
        }}
      >
        {
          detailings?.map((detailing) => (
            <ColourOption
              key={detailing.id}
              colour={detailing}
              onPress={() => { onDetailingSelect(detailing); }}
              active={selected(detailing)}
            />
          ))
        }
      </View>
    </ScrollView>
  );
}
