import React from 'react';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import {
  View, Text, useWindowDimensions, ScrollView,
} from 'react-native';
import { RootStackParamList } from '../types/routes';
import { useAppSelector, useAppDispatch } from '../hooks/store';
import { refresh as refreshProductStore } from '../store/productSlice';
import { refresh as refreshCustomerStore } from '../store/customerSlice';
import BackButton from '../components/BackButton';
import AppContainer from '../components/AppContainer';
import ProductPreview from '../components/ProductPreview';
import NavigationBar from '../components/NavigationBar';
import HeaderSection from '../components/HeaderSection';
import ScrollViewHeader from '../components/ScrollViewHeader';
import ConditionalButton from '../components/ConditionalButton';

type Props = NativeStackScreenProps<RootStackParamList, 'Customer Details'>

export default function CustomerDetailsScreen({ navigation } : Props) {
  const { width } = useWindowDimensions();
  const dispatch = useAppDispatch();
  const breakPoint = width > 700;
  const restart = () => {
    dispatch(refreshProductStore());
    dispatch(refreshCustomerStore());
    navigation.navigate('Home');
  };
  const renderCustomerDetails = () => (
    <View style={{ alignItems: 'center', justifyContent: 'center' }}>
      <ScrollViewHeader title="Your Design" />
      <View style={{
        width: '100%', justifyContent: 'center', display: 'flex', flexDirection: width > 700 ? 'row' : 'column', marginTop: 40, marginBottom: 80,
      }}
      >
        <View style={{ width: breakPoint ? '50%' : '100%' }}>
          <ProductPreview />
        </View>
        <View style={{ alignItems: 'center', justifyContent: 'center', width: breakPoint ? '50%' : '100%', marginTop: breakPoint ? 0 : 30 }}>
          <View style={{ alignItems: 'flex-start', width: breakPoint ? '100%' : '75%', paddingHorizontal: breakPoint ? 50 : 10 }}>
            <Text style={{ fontFamily: 'nimbus', fontSize: 24, fontWeight: 'bold', marginBottom: 30, textAlign: breakPoint ? 'left' : 'center' }}>Congratulations on designing your Luxury Security Safe.</Text>
            <Text style={{ fontFamily: 'nimbus', fontSize: 16, marginBottom: 10, textAlign: breakPoint ? 'left' : 'center' }}>A PDF copy of your design has been emailed to you.</Text>
            <Text style={{ fontFamily: 'nimbus', fontSize: 16, marginBottom: 10, textAlign: breakPoint ? 'left' : 'center' }}>We have also received a copy and a member of our specialist team will contact you within the next 24 hours!</Text>
            <Text style={{ fontFamily: 'nimbus', fontSize: 14, textAlign: breakPoint ? 'left' : 'center' }}>Please check your spam folder if not received within a few minutes of sending.</Text>
          </View>
        </View>
      </View>
    </View>
  );

  return (
    <>
      <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
        <HeaderSection navigation={navigation} />
        <AppContainer>
          {renderCustomerDetails()}
        </AppContainer>
      </ScrollView>
      <NavigationBar>
        <BackButton />
        <ConditionalButton title="Restart" condition onPress={restart} />
      </NavigationBar>
    </>
  );
}
