import { createSlice } from '@reduxjs/toolkit';
import { ContentSection } from '../types/index';
interface ClientState {
  code: string | null;
  contentSections: [ContentSection];
  footer_header_background_colour: string | null;
  footer_text_1: string | null;
  footer_text_2: string | null;
  logoUrl: string | null;
  product_brochure: string;
}

const initialState : ClientState = {
  android_app_link: null,
  code: null,
  contentSections: [],
  ios_app_link: null,
  logoUrl: null,
  footer_header_background_colour: null,
  footer_text_1: null,
  footer_text_2: null,
};

export const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    updateClientData: (state, {
      payload: {
        android_app_link, code, content_sections, footer_header_background_colour,
        footer_text_1, footer_text_2, ios_app_link, logo_url, product_brochure,
      },
    }) => {
      state.android_app_link = android_app_link;
      state.code = code;
      state.contentSections = content_sections;
      state.footer_header_background_colour = footer_header_background_colour;
      state.footer_text_1 = footer_text_1;
      state.footer_text_2 = footer_text_2;
      state.ios_app_link = ios_app_link;
      state.logoUrl = logo_url;
      state.product_brochure = product_brochure;
    },
  },
});

export const {
  updateClientData,
} = clientSlice.actions;

export default clientSlice.reducer;
