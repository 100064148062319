import React, { useEffect } from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as Font from 'expo-font';
import { Provider } from 'react-redux';
import HomeScreen from './screens/HomeScreen';
import RangeScreen from './screens/RangeScreen';
import ProductListScreen from './screens/ProductListScreen';
import ProductScreen from './screens/ProductScreen';
import CustomerDetailsScreen from './screens/CustomerDetailsScreen';
import SummaryScreen from './screens/SummaryScreen';
import DesignCompleteScreen from './screens/DesignCompleteScreen';
import { store } from './store/index';
import ClientData from './components/ClientData';
import AllProductsData from './components/AllProductsData';

const Stack = createNativeStackNavigator();

export default function App() {
  useEffect(() => {
    async function loadResources() {
      try {
        // Load fonts
        await Font.loadAsync({
          eurostile: require('./assets/fonts/EurostileNextLTPro-Bold.otf'),
          nimbus: require('./assets/fonts/NimbusSan-Regular.otf'),
        });
      } catch (e) {
        console.warn(e);
      }
    }
    loadResources();
  }, []);
  return (
    <Provider store={store}>
      <SafeAreaProvider>
        <ClientData />
        <AllProductsData />
        <NavigationContainer documentTitle={{
          formatter: () => 'burtonsafes.design | Design Security your Way' }}
        >
          <Stack.Navigator screenOptions={{ contentStyle: { backgroundColor: 'white' } }}>
            <Stack.Screen name="Home" component={HomeScreen} options={{ headerShown: false }} />
            <Stack.Screen name="Range" component={RangeScreen} options={{ headerShown: false }} />
            <Stack.Screen name="Products List" component={ProductListScreen} options={{ headerShown: false }} />
            <Stack.Screen name="Product" component={ProductScreen} options={{ headerShown: false }} />
            <Stack.Screen name="Customer Details" component={CustomerDetailsScreen} options={{ headerShown: false }} />
            <Stack.Screen name="Summary" component={SummaryScreen} options={{ headerShown: false }} />
            <Stack.Screen name="Design Complete" component={DesignCompleteScreen} options={{ headerShown: false }} />
          </Stack.Navigator>
        </NavigationContainer>
      </SafeAreaProvider>
    </Provider>
  );
}
