import React from 'react';
import {
  View, Image, Pressable, Text,
} from 'react-native';
import { Drawer } from '../types';

type Props = {
  onPress(): void;
  drawer: Drawer;
  selected: boolean;
};

const logo = require('../assets/icon.png');

// TODO - this and Module details are currently almost identiacal
// it could perhaps be refactored
export default function DrawerDetails({
  onPress, drawer, selected,
} : Props) {
  return (
    <Pressable onPress={onPress}>
      <View
        style={{
          flexDirection: 'row',
          padding: 10,
          width: 250,
          backgroundColor: '#fff',
          borderColor: selected ? '#02969e' : 'transparent',
          borderWidth: 3,
          marginBottom: 10,
          marginRight: 10,
          alignItems: 'center',
        }}
      >
        <View style={{ width: 150, height: 80 }}>
          <Image
            style={{ width: '100%', height: '100%' }}
            source={drawer.image ? { uri: drawer.image } : logo}
            resizeMode="contain"
          />
        </View>
        <View style={{ flex: 1, justifyContent: 'center' }}>
          <Text style={{ textAlign: 'center' }}>{drawer.name}</Text>
        </View>
      </View>
    </Pressable>
  );
}
