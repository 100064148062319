import React from 'react';
import {
  Image, Platform, Pressable, StyleSheet, View,
} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useAppSelector } from '../hooks/store';

const isApp = Platform.OS !== 'web';

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 10,
  },
  clientLogo: {
    height: isApp ? 50 : 65,
    resizeMode: 'contain',
    width: 300,
  },
});

export default function HeaderSection({ navigation }) {
  const { footer_header_background_colour, logoUrl } = useAppSelector(({ client }) => client);

  return (
    <SafeAreaView style={[styles.container, { backgroundColor: footer_header_background_colour }]}>
      { logoUrl ? (
        <Pressable onPress={() => navigation.navigate('Home')}>
          <Image
            source={{ uri: logoUrl }}
            style={styles.clientLogo}
          />
        </Pressable>
      )
        : (
          null
        )}
    </SafeAreaView>
  );
}
