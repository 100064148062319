import React, { useState, useEffect } from 'react';
import {
  Image, View, Platform, Pressable, Text, ScrollView,
} from 'react-native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import BackButton from '../../components/BackButton';
import { useAppSelector, useAppDispatch } from '../../hooks/store';
import { updateSelectedModules, refreshSelectedModules } from '../../store/productSlice';
import { SelectedModule } from '../../types';
import { ProductStackParamList } from '../../types/routes'
import { Layout } from '../../types/index';
import ConditionalButton from '../../components/ConditionalButton';
import AppContainer from '../../components/AppContainer';
import NavigationBar from '../../components/NavigationBar';
import { hasDrawers } from '../../helpers/module_helpers';
import HeaderSection from '../../components/HeaderSection';
import ScrollViewHeader from '../../components/ScrollViewHeader';

type Props = NativeStackScreenProps<ProductStackParamList, 'Select Modules'>

export default function LayoutSelectScreen({ navigation } : Props) {
  const dispatch = useAppDispatch();
  const size = useAppSelector(({ product }) => product.size);
  const modules = size?.modules;
  const selectedModules = useAppSelector(({ product }) => product.selectedModules);
  const layouts = size?.layouts;
  const [currentSelection, setCurrentSelection] = useState<Layout | null>(null);
  const isApp = Platform.OS !== 'web';

  // LAYOUT is not stored and selecting a new layout will remove selected modules
  // this deselects anything added from preset select...

  const storeSelectedModules = () => {
    dispatch(refreshSelectedModules());

    currentSelection?.modules?.forEach((layout) => {
      const selected = modules?.find((m) => m.id === layout.module_id);
      if (!selected) return;
      dispatch(updateSelectedModules({ selected, position: layout.position }));
    });
  };
  const onLayoutPress = (layout: Layout) => () => {
    setCurrentSelection(layout);
  };

  useEffect(() => {
    if (currentSelection) {
      storeSelectedModules();
    }
  }, [currentSelection]);

  const isActive = (layout: Layout) => currentSelection?.id === layout.id;

  const renderLayout = (layout: Layout, index: number) => {
    const layoutIsActive = isActive(layout);

    return (
      <Pressable
        onPress={onLayoutPress(layout)}
        key={index.toString()}
      >
        <View
          style={[
            layoutIsActive
              ? {
                borderColor: '#02969e',
              }
              : { borderColor: '#fff' },
            {
              alignItems: 'center',
              backgroundColor: '#fff',
              borderRadius: 20,
              borderWidth: 2,
              height: 300,
              padding: isApp ? 5 : 10,
              margin: 10,
              marginHorizontal: isApp ? 5 : 10,
              paddingVertical: 20,
              width: isApp ? 200 : 220,
            },
          ]}
        >
          <Image
            style={{ width: 200, height: 200 }}
            source={{ uri: layout.image }}
          />
          <Text style={{ textAlign: 'center', marginTop: 20, paddingHorizontal: 10, fontSize: 16, color: '#5f5f5f', fontFamily: 'nimbus' }}>{layout.name}</Text>
        </View>
      </Pressable>
    );
  };

  const onNext = () => {
    if (!currentSelection || !selectedModules) return;
    const selectedValues = Object.values(selectedModules);

    const drawerSelect = selectedValues.some(
      (item : SelectedModule) => (
        hasDrawers(item)
      ),
    );
    if (drawerSelect) {
      navigation.navigate('Select Drawers');
    } else {
      navigation.navigate('Select Options');
    }
  };

  const canContinue = () => !!currentSelection && !!selectedModules;

  return (
    <View style={{flexGrow: 1}}>
      <HeaderSection navigation={navigation} />
      <AppContainer>
        <View style={{ flex: 1, alignItems: 'center', justifyContent: 'flex-start' }}>
          <ScrollViewHeader title="Choose a Layout for your Valuables" />
          <ScrollView
            horizontal
            style={{ maxWidth: '100%', flexGrow: 0 }}
            contentContainerStyle={{ flexGrow: 1, justifyContent: 'space-evenly', paddingVertical: 20 }}
          >
            {
              layouts?.map((layout, index) => renderLayout(layout, index))
            }
          </ScrollView>
        </View>
      </AppContainer>
      <NavigationBar>
        <BackButton />
        <ConditionalButton condition={canContinue()} onPress={onNext} title="Next" />
      </NavigationBar>
    </View>
  );
}
