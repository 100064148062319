// import * as Updates from 'expo-updates';

import Constants from 'expo-constants';

// When building or releasing app use CLIENT_CODE env variable
// Examples:
// CLIENT_CODE="safes" expo build:ios
// CLIENT_CODE="safes" expo start

export default function getClientCode() {
  const clientCode = Constants.manifest?.extra?.clientCode;
  return clientCode || 'burton';
}
