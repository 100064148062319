import React from 'react';
import {
  Image, Platform, Pressable, StyleSheet, Text, View,
} from 'react-native';
import { Product } from '../types/index';

const logo = require('../assets/icon.png');

type Props = {
  product: Product;
  onPress(): void;
  active: boolean;
}

const isApp = Platform.OS !== 'web';

const styles = StyleSheet.create({
  colourSample: {
    borderRadius: 10,
    borderWidth: 0.5,
    height: 20,
    marginTop: 10,
    marginRight: 5,
    width: 20,
  },
  container: {
    alignItems: 'center',
    backgroundColor: '#f6f6f6',
    borderRadius: 20,
    borderWidth: 2,
    flexDirection: 'column',
    justifyContent: 'center',
    marginHorizontal: isApp ? 20 : 30,
    paddingVertical: isApp ? 20 : 30,
    width: isApp ? 275 : 300,
  },
  heading: {
    fontWeight: 'bold',
    fontSize: 20,
    marginTop: 10,
  },
  presetImage: {
    height: isApp ? 275 : 300,
    width: 250,
  },
});

export default function ProductSummary({ product, onPress, active } : Props) {
  const pluralise = (count: number, singular: string) => (count === 1 ? singular : `${singular}s`);
  const { safe_colour_previews } = product;

  return (
    <Pressable
      style={[styles.container, { borderColor: active ? '#00959a' : '#f6f6f6' }]}
      onPress={onPress}
    >
      <View style={{ marginBottom: isApp ? 10 : 20 }}>
        <Image
          source={product.image ? { uri: product.image } : logo}
          style={styles.presetImage}
          resizeMode="contain"
        />
      </View>
      <View style={{ paddingHorizontal: 20, width: '100%' }}>
        <Text style={styles.heading}>
          { product.name }
        </Text>
        <Text style={{ color: '#444', fontStyle: 'italic', marginTop: 5 }}>
          {`${product.grades.length} `}
          {`${pluralise(product.grades.length, 'Grade')} `}
          Available
        </Text>
        <Text style={{ marginTop: 5 }}>
          Colour options
        </Text>
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          {
            safe_colour_previews.map((sc, i) => (
              <View
                key={i}
                style={[styles.colourSample, { backgroundColor: `${sc}` }]}
              />
            ))
          }
        </View>
      </View>
    </Pressable>
  );
}
