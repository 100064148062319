import { createSlice } from '@reduxjs/toolkit';
import { Product } from '../types/index';

interface AllProductsState {
  products: [Product];
}

const initialState : AllProductsState = {
  products: [],
};

export const allProductsSlice = createSlice({
  name: 'allProducts',
  initialState,
  reducers: {
    updateAllProductsData: (state, {
      payload: {
        products,
      },
    }) => {
      state.products = products;
    },
  },
});

export const {
  updateAllProductsData,
} = allProductsSlice.actions;

export default allProductsSlice.reducer;
