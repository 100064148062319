import { configureStore } from '@reduxjs/toolkit';
import productReducer from './productSlice';
import customerSlice from './customerSlice';
import clientSlice from './clientSlice';
import allProductsSlice from './allProductsSlice';

export const store = configureStore({
  reducer: {
    allProducts: allProductsSlice,
    product: productReducer,
    customer: customerSlice,
    client: clientSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
