import React from 'react';
import { Platform, Text, View } from 'react-native';
import Icon from 'react-native-vector-icons/AntDesign';

export default function ScrollViewHeader({ title }) {
  const isApp = Platform.OS !== 'web';
  return (
    <View
      style={{
        alignItems: 'center',
        alignSelf: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingVertical: isApp ? 0 : 10,
        minWidth: '30%',
      }}
    >
      <>
        <Icon name="arrowleft" color="#333" size={20} />
      </>
      <>
        <Text style={{ paddingHorizontal: 20, textAlign: 'center' }}>
          {title}
        </Text>
      </>
      <>
        <Icon name="arrowright" color="#333" size={20} />
      </>
    </View>
  );
}
