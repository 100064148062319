import React, { useState } from 'react';
import {
  Image, Platform, ScrollView,
  StyleSheet, Text, useWindowDimensions, View,
} from 'react-native';
import { Button as EButton, Icon } from 'react-native-elements';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { ProductStackParamList } from '../../types/routes'
import { useAppSelector } from '../../hooks/store';
import { allDrawersSelectedForModule, hasDrawers } from '../../helpers/module_helpers';
import { SelectedModule } from '../../types';
import Modal from '../../components/Modal';
import DrawerBox from '../../components/DrawerBox';
import NavigationBar from '../../components/NavigationBar';
import AppContainer from '../../components/AppContainer';
import BackButton from '../../components/BackButton';
import ConditionalButton from '../../components/ConditionalButton';
import DrawerOptionsScreen from './DrawerOptionsScreen';
import HeaderSection from '../../components/HeaderSection';
import ScrollViewHeader from '../../components/ScrollViewHeader';
import responsiveDimensions from '../../helpers/responsiveDimensions'
import ModuleDrawerSelect from '../../components/ModuleDrawerSelect';

type Props = NativeStackScreenProps<ProductStackParamList, 'Select Drawers'>

const styles = StyleSheet.create({
  container: {
    height: 600,
    resizeMode: 'contain',
    width: 300,
  },
  moduleLayoutImageContainer: {
    top: 0,
    zIndex: -1,
  },
});

export default function DrawersSelectScreen({ navigation } : Props) {
  const { selectedModules, size } = useAppSelector(({ product }) => product);
  const [currentSelection, setCurrentSelection] = useState<SelectedModule | null>(null);
  const [screenLoad, setScreenLoad] = useState<boolean>(true);
  const [modalVisibility, setModalVisibility] = useState<boolean>(false);
  const { width } = useWindowDimensions();
  const breakPoint = 700;
  const isApp = Platform.OS !== 'web';

  const onModulePress = (value: SelectedModule) => {
    if (hasDrawers(value)) {
      setCurrentSelection(value);
      setModalVisibility(true);
    }
  };

  // preselect the first module with drawers on desktop
  if (screenLoad && selectedModules && width > breakPoint) {
    onModulePress(Object.values(selectedModules).find((v) => hasDrawers(v)));
    setScreenLoad(false);
  }

  const displayModal = () => !!(currentSelection && modalVisibility);

  const canContinue = () => {
    const selectedValues = Object.values(selectedModules);
    return selectedValues.every(
      (item : SelectedModule) => (
        allDrawersSelectedForModule(item)
      ),
    );
  };

  const onNext = () => {
    if (canContinue()) navigation.navigate('Select Options');
  };

  /*
    sets height for background image, either use api value or calculate based on number of
    module spaces * height of a single module
  */
  const adjustedHeight = size?.safe_height || (Object.values(selectedModules)
    .map((sm) => sm.no_of_spaces)
    .reduce((a, b) => a + b, 0) * size?.module_height) + size?.module_height;
  return (
    <>
      <HeaderSection navigation={navigation} />
      <AppContainer>
        <ScrollView contentContainerStyle={{ alignItems: 'center', flexGrow: 1 }}>
          <ScrollViewHeader title="Choose your Jewellery Trays" />
          <View style={{ justifyContent: width > breakPoint ? 'flex-start' : 'center', alignItems: 'center', flexDirection: 'row', flexGrow: 1, width: '100%', marginTop: 35 }}>
            <View style={{ width: width > breakPoint ? '50%' : '100%', position: 'absolute', left: width > breakPoint ? '5%' : null, top: 0, alignContent: 'center' }}>
              { /*
                availableModules prop is a misleading name as we actually pass
                selected modules here
              */ }
              <ModuleDrawerSelect
                availableModules={Object.values(selectedModules)}
                currentSelection={currentSelection}
                drawerSelect
                onModulePress={onModulePress}
                selectedModules={selectedModules}
                size={size}
              />
            </View>
            { currentSelection && width <= breakPoint ? (
              <Modal
                display={displayModal()}
              >
                <View style={{ alignItems: 'center', marginBottom: 12 }}>
                  <DrawerBox
                    active
                    onPress={() => {}}
                  >
                    <View style={{ alignItems: 'center', flexDirection: 'row', marginLeft: 5, justifyContent: 'space-between', opacity: hasDrawers(selectedModules[currentSelection.position]) ? 1 : 0.5 }}>
                      <Text>
                        {allDrawersSelectedForModule(selectedModules[currentSelection.position]) ? <Icon name="check" color="#02969e" size={width > 900 ? 24 : 18} /> : null}
                      </Text>
                      <Text style={{ color: '#02969e', fontFamily: 'nimbus', marginLeft: allDrawersSelectedForModule(selectedModules[currentSelection.position]) ? 5 : 24, fontSize: 14 }}>
                        {selectedModules[currentSelection.position].name}
                      </Text>
                    </View>
                  </DrawerBox>
                </View>
                <ScrollView>
                  <View style={{ marginBottom: 10 }}>
                    <DrawerOptionsScreen
                      currentSelection={selectedModules[currentSelection.position]}
                    />
                  </View>
                  <View style={{ alignItems: 'center', marginBottom: isApp ? 180 : 0 }}>
                    <ConditionalButton title="Done" condition onPress={() => setModalVisibility(false)} />
                  </View>
                </ScrollView>
              </Modal>
            ) : null}

            { currentSelection && width > breakPoint ? (
              <View style={{ alignSelf: 'flex-start', marginBottom: 10, width: '50%', position: 'absolute', right: 0 }}>
                <DrawerOptionsScreen
                  currentSelection={currentSelection}
                />
              </View>
            ) : null}
            <View
              style={
                [styles.moduleLayoutImageContainer,
                  {
                    left: width > breakPoint ? '5%' : 'auto',
                    position: 'absolute',
                    height: responsiveDimensions(adjustedHeight, width),
                    width: responsiveDimensions(450, width),
                    alignSelf: 'center',
                  },
                ]
              }
            >
              <Image
                style={{ height: '100%', width: '100%' }}
                source={{ uri: size.module_preview_image }}
                resizeMode="contain"
              />
            </View>
          </View>
        </ScrollView>
      </AppContainer>
      <NavigationBar>
        <BackButton />
        <ConditionalButton condition={canContinue()} onPress={onNext} title="Next" />
      </NavigationBar>
    </>
  );
}
