import React from 'react';
import {
  View, ScrollView,
} from 'react-native';
import { useAppSelector, useAppDispatch } from '../../hooks/store';
import { updateSelectedFinish } from '../../store/productSlice';
import { ModuleFinish, SelectedModule } from '../../types';
import ColourOption from '../../components/ColourOption';

type Props = {
  currentSelection: SelectedModule;
}

export default function FinishSelectionScreen({ currentSelection } : Props) {
  const dispatch = useAppDispatch();
  const selectedGrade = useAppSelector(({ product }) => product.grade);
  const selectedModules = useAppSelector(({ product }) => product.selectedModules);
  const moduleFinishes = useAppSelector(({ product }) => product.size?.module_finishes);

  const onFinishSelect = (finish: ModuleFinish) => {
    if (!currentSelection) return;
    // Only update finish for current module
    if (selectedGrade?.module_finishes_customisable) {
      const data = { selectedModule: currentSelection, finish };
      dispatch(updateSelectedFinish(data));
      return;
    }
    // update finish for all modules
    Object.values(selectedModules).forEach((m) => {
      const data = { selectedModule: m, finish };
      dispatch(updateSelectedFinish(data));
    });
  };

  const selected = (finish: ModuleFinish): boolean => (
    selectedModules[currentSelection.position].selectedFinish?.id === finish.id
  );

  return (
    <ScrollView>
      <View
        style={{
          flexDirection: 'row', flexWrap: 'wrap', flex: 1, justifyContent: 'center',
        }}
      >
        { currentSelection
          ? (
            moduleFinishes?.map((finish) => (
              <ColourOption
                key={finish.id}
                colour={finish}
                onPress={() => { onFinishSelect(finish); }}
                active={selected(finish)}
              />
            ))
          )
          : null}
      </View>
    </ScrollView>
  );
}
