import React, { useState } from 'react';
import {
  ActivityIndicator, Image, Platform, ScrollView, StyleSheet,
  Text, TouchableOpacity, useWindowDimensions, View,
} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { useAppDispatch, useAppSelector } from '../hooks/store';
import { RootStackParamList } from '../types/routes';
import { refresh as refreshProductStore } from '../store/productSlice';
import { refresh as refreshCustomerStore } from '../store/customerSlice';
import RangeItem from '../components/RangeItem';
import HomeNavigation from '../components/HomeNavigation';
import ContentSectionContainer from '../components/ContentSectionContainer';
import { ContentSection, Product } from '../types/index';
import Footer from '../components/Footer';

type Props = NativeStackScreenProps<RootStackParamList, 'Home'>

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    backgroundColor: '#02969e',
    borderRadius: 20,
    marginTop: 15,
    paddingHorizontal: 20,
    paddingVertical: 12,
  },
  buttonText: {
    color: '#fff',
    fontSize: 18,
    fontWeight: '600',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  rangeContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: 20,
    overflow: 'hidden',
    width: '100%',
  },
  heading: {
    fontFamily: 'eurostile',
    fontSize: 48,
    marginBottom: 50,
    paddingHorizontal: 20,
    textAlign: 'center',
  },
  pageMargin: {
    alignSelf: 'center',
    maxWidth: 1200,
    width: '80%',
  },
  paragraph: {
    fontSize: 24,
    textAlign: 'center',
  },
});

export default function HomeScreen({ navigation } : Props) {
  const [mainScroller, setMainScroller] = useState(null);
  const [loadingTimeout, setLoadingTimeout] = useState(false);
  const client = useAppSelector(({ client }) => client);
  const { footer_header_background_colour, logoUrl } = client;
  const { contentSections } = useAppSelector(({ client }) => client);
  const { products } = useAppSelector(({ allProducts }) => allProducts);
  const { height, width } = useWindowDimensions();
  const dispatch = useAppDispatch();
  const isApp = Platform.OS !== 'web';
  const heightAdjustment = width > 500 && height > 600 ? 130 : 80;
  const scrollToRef = () => {
    if (mainScroller) {
      // Offset by height of navigation bar - 85px
      mainScroller.scrollTo({
        y: height < 1000 ? (height - heightAdjustment) - 85 : 1000,
        animated: true,
      });
    }
  };

  setTimeout(() => {
    if (products.length <= 0) {
      setLoadingTimeout(true);
    }
  }, 10000);

  const renderProduct = (item: Product) => (
    <RangeItem
      key={item.id.toString()}
      product={item}
      onPress={() => navigation.navigate('Range', { productId: String(item.id) })}
    />
  );

  const onNext = () => {
    dispatch(refreshProductStore());
    dispatch(refreshCustomerStore());
    return navigation.navigate('Products List');
  };

  const renderButton = () => (
    <TouchableOpacity onPress={() => onNext()}>
      <View style={styles.button}>
        <Text style={styles.buttonText}>Start your design</Text>
      </View>
    </TouchableOpacity>
  );

  const renderContentSection = (item: ContentSection) => (
    <View key={item.position}>
      {
        item.title === 'SHOW PRODUCTS' ? (
          <View style={styles.rangeContainer}>
            <Text style={styles.heading}>
              Featured Product Ranges
            </Text>
            <ScrollView
              horizontal
              style={{ maxWidth: '100%', flexGrow: 0 }}
              contentContainerStyle={{ justifyContent: 'space-evenly', paddingBottom: 20 }}
            >
              {
                products.map((p) => (
                  renderProduct(p)
                ))
              }
            </ScrollView>
          </View>
        ) : (
          <ContentSectionContainer
            key={item.id.toString()}
            contentSection={item}
            navigation={navigation}
            renderButton={item.position === 1 ? renderButton : null}
            scrollToRef={scrollToRef}
          />
        )
      }
    </View>
  );
  const renderHomeScreen = () => {
    if (products.length <= 0) {
      return (
        <View style={{ height, width, justifyContent: 'center', alignItems: 'center' }}>
          {
            loadingTimeout ? (
              <Text style={{ paddingHorizontal: 40 }}>Oops sorry something went wrong! Our app requires data to allow you to design your security safe. Please check your connection.</Text>
            ) : (
              <>
                <Image
                  source={{ uri: logoUrl }}
                  style={{ width: 350, height: 100, marginBottom: 10 }}
                  resizeMode="contain"
                />
                <ActivityIndicator size="large" color="#00959a" />
              </>
            )
          }
        </View>
      );
    }
    return (
      <>
        <HomeNavigation
          footer_header_background_colour={footer_header_background_colour}
          logoUrl={logoUrl}
          navigation={navigation}
        />
        <ScrollView
          ref={(mainScroller) => {
            if (mainScroller !== null) {
              setMainScroller(mainScroller);
            }
          }}
        >
          {
            contentSections.map((c) => (
              renderContentSection(c)
            ))
          }
          <Footer />
        </ScrollView>
      </>
    );
  };

  return (
    isApp ? (
      <SafeAreaView>
        { renderHomeScreen() }
      </SafeAreaView>
    ) : renderHomeScreen()
  );
}
