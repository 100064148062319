import React from 'react';
import {
  View, Image, Pressable, Text, useWindowDimensions,
} from 'react-native';
import { Module } from '../types';

type Props = {
  onPress(): void;
  module: Module;
  selected: boolean;
};

export default function ModuleDetails({
  onPress, module, selected,
} : Props) {
  const { width } = useWindowDimensions();
  const desktop = width > 940 || (width < 700 && width > 500);
  return (
    <Pressable
      onPress={onPress}
      style={{
        alignSelf: 'center',
        alignItems: 'center',
        backgroundColor: '#fff',
        borderColor: selected ? '#02969e' : '#fff',
        borderWidth: 2,
        borderRadius: 10,
        flexDirection: desktop ? 'row' : 'column',
        margin: 10,
        padding: 10,
        width: desktop ? 360 : 275,
      }}
    >
      <Image
        style={{ width: 180, height: 90, marginBottom: desktop ? 0 : 15 }}
        source={{ uri: module.image }}
        resizeMode="contain"
      />
      <View style={{ justifyContent: 'center', width: 160 }}>
        <Text style={{ fontWeight: 'bold', fontFamily: 'nimbus', textAlign: 'center' }}>{module.name}</Text>
      </View>
    </Pressable>
  );
}
