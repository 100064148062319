import { iteratorSymbol } from 'immer/dist/internal';
import React from 'react';
import {
  Pressable, StyleSheet, Text, View, Image,
} from 'react-native';
import { Product } from '../types/index';

type Props = {
  product: Product;
  onPress(): void;
}

const styles = StyleSheet.create({
  colourSample: {
    borderRadius: 10,
    borderWidth: 0.5,
    height: 20,
    marginTop: 10,
    marginRight: 5,
    width: 20,
  },
  container: {
    alignItems: 'center',
    backgroundColor: '#f6f6f6',
    borderRadius: 20,
    flexDirection: 'column',
    justifyContent: 'center',
    marginHorizontal: 30,
    paddingVertical: 30,
    width: 300,
  },
  heading: {
    fontWeight: 'bold',
    fontSize: 20,
    marginTop: 10,
  },
  logoImage: {
    height: 72,
    resizeMode: 'contain',
    width: 300,
  },
  productImage: {
    height: 300,
    width: 250,
  },
  textContainer: {
    paddingHorizontal: 20,
  },
});

export default function RangeItem({ product, onPress } : Props) {
  const pluralise = (count: number, singular: string) => (count === 1 ? singular : `${singular}s`);
  const { safe_colour_previews } = product;
  return (
    <Pressable
      style={styles.container}
      onPress={onPress}
    >
      <View>
        {
          product.image ? (
            <Image
              source={{ uri: product.image }}
              style={styles.productImage}
              resizeMode="contain"
            />
          ) : <></>
        }
        <View style={styles.textContainer}>
          <Text style={styles.heading}>{product.name}</Text>
          <Text style={{ color: '#444', fontStyle: 'italic', marginTop: 5 }}>
            {`${product.grades.length} `}
            {`${pluralise(product.grades.length, 'Grade')} `}
            Available
          </Text>
          <Text style={{ marginTop: 5 }}>Colour options</Text>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            {
              safe_colour_previews.map((sc, i) => (
                <View
                  key={i}
                  style={[styles.colourSample, { backgroundColor: `${sc}` }]}
                />
              ))
            }
          </View>
        </View>
      </View>
    </Pressable>
  );
}
