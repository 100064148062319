import React from 'react';
import {
  StyleSheet, Image, Platform, Pressable,
} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

interface Props {
  footer_header_background_colour: string;
  logoUrl: string;
}

const styles = StyleSheet.create({
  clientLogo: {
    height: 65,
    marginVertical: 15,
    resizeMode: 'contain',
    width: 300,
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    marginTop: Platform.OS === 'web' ? 0 : 0,
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: 2,
  },
});

export default function HomeNavigation({ footer_header_background_colour, logoUrl, navigation } : Props) {
  return (
    <SafeAreaView style={[styles.container, { backgroundColor: footer_header_background_colour }]}>
      <Pressable onPress={() => navigation.navigate('Home')}>
        <Image
          source={{ uri: logoUrl }}
          style={styles.clientLogo}
        />
      </Pressable>
    </SafeAreaView>
  );
}
