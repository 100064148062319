import React from 'react';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import {
  Platform, ScrollView, Text, View,
} from 'react-native';
import { useAppSelector, useAppDispatch } from '../hooks/store';
import { RootStackParamList } from '../types/routes';
import BackButton from '../components/BackButton';
import ProductSummary from '../components/ProductSummary';
import { Product } from '../types/index';
import { updateProduct } from '../store/productSlice';
import ConditionalButton from '../components/ConditionalButton';
import AppContainer from '../components/AppContainer';
import NavigationBar from '../components/NavigationBar';
import HeaderSection from '../components/HeaderSection';
import ScrollViewHeader from '../components/ScrollViewHeader';

type Props = NativeStackScreenProps<RootStackParamList, 'Products List'>

const next = (navigation : Props['navigation'], product : Product | null) => {
  if (product) {
    navigation.navigate('Product');
  }
};

export default function ProductListScreen({ navigation } : Props) {
  const { products } = useAppSelector(({ allProducts }) => allProducts);
  const product = useAppSelector(
    ({ product: productStore }) => productStore.product,
  );
  const dispatch = useAppDispatch();
  const isApp = Platform.OS !== 'web';

  const renderProduct = (item: Product) => (
    <ProductSummary
      key={item.id.toString()}
      active={!!(product && product.id === item.id)}
      product={item}
      onPress={() => dispatch(updateProduct(item))}
    />
  );

  return (
    <>
      <HeaderSection navigation={navigation} />
      <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
        <AppContainer>
          <View style={{ alignItems: 'center', justifyContent: 'flex-start' }}>
            <ScrollViewHeader title="Choose a Safe Range to Customise" />
            <ScrollView
              horizontal
              style={{ maxWidth: '100%', flexGrow: 0 }}
              contentContainerStyle={{ flexGrow: 1, marginBottom: 10, justifyContent: 'space-evenly', paddingVertical: 10 }}
            >
              {
                products.map((p) => (
                  renderProduct(p)
                ))
              }
            </ScrollView>
          </View>
        </AppContainer>
      </ScrollView>
      <NavigationBar>
        <BackButton />
        <ConditionalButton condition={!!product} onPress={() => next(navigation, product)} title="Next" />
      </NavigationBar>
    </>
  );
}
