import React from 'react';
import {
  StyleSheet, Text, TouchableOpacity, useWindowDimensions, View,
} from 'react-native';
import { useNavigation } from '@react-navigation/native';

const defaultProps = {
  title: 'Back',
};

type Props = {
  title?: string;
} & typeof defaultProps;

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    backgroundColor: '#333333',
    borderRadius: 12,
    paddingVertical: 15,
  },
  buttonText: {
    color: '#fff',
    fontWeight: '600',
    fontSize: 18,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
});

const BackButton = ({ title } : Props) => {
  const navigation = useNavigation();
  const { width } = useWindowDimensions();
  return (
    <TouchableOpacity onPress={() => navigation.goBack()}>
      <View style={[styles.button, { width: width > 400 ? 150 : 120 }]}>
        <Text style={styles.buttonText}>{title}</Text>
      </View>
    </TouchableOpacity>
  );
};

BackButton.defaultProps = defaultProps;

export default BackButton;
