import React from 'react';
import { View } from 'react-native';

type Props = {
  children: any;
}

export default function Modal({
  children,
} : Props) {
  return (
    <View style={{ flex: 1 }}>
      <View
        style={{
          alignSelf: 'center',
          minHeight: '100%',
          maxWidth: '100%',
          padding: 16,
          width: 1200,
        }}
      >
        { children }

      </View>
    </View>
  );
}
