import baseUrl from './baseUrl';
import getClientCode from '../helpers/getClientCode';

export default async function fetchRangeData(safeId) : Promise<any> {
  const clientCode = getClientCode();
  const apiBase = `${baseUrl}/get_range_data?safe_id=${safeId}&client_code=${clientCode}`;

  try {
    const url = apiBase;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    const data: any = await response.json();

    return data;
  } catch (e) {
    console.log(`ERROR${e}`);

    return Promise.reject();
  }
}
