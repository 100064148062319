import React, { useEffect, useState } from 'react';
import { Image, ScrollView, StyleSheet, View } from 'react-native';
import { AvailableModule, Module, SelectedModule, SelectedModules, Size } from '../types';
import { updateSelectedModules, updateVirtualPositions } from '../store/productSlice';
import { useAppDispatch, useAppSelector } from '../hooks/store';
import ModuleDrawerSelect from './ModuleDrawerSelect';
import responsiveDimensions from '../helpers/responsiveDimensions';
import Modal from './Modal';
import ModuleDetails from './ModuleDetails';

type Props = {
  adjustedHeight: number;
  allowedModules: [any];
  availableModules: [AvailableModule];
  breakPoint: number;
  currentSelection: AvailableModule | null;
  modalVisibility: boolean;
  selectedModules: SelectedModules;
  setAvailableModules(): void;
  setCurrentSelection(): void;
  setModalVisibility(): void;
  size: Size;
  width: number;
}

const styles = StyleSheet.create({
  container: {
    height: 600,
    resizeMode: 'contain',
    width: 300,
  },
  moduleLayoutImageContainer: {
    top: 0,
    zIndex: -1,
  },
});

export default function ModuleBuilder({
  adjustedHeight, allowedModules, availableModules, breakPoint, currentSelection, modalVisibility,
  selectedModules, setAvailableModules, setModalVisibility, setCurrentSelection, size, width,
} : Props) {
  const [firstAvailableModule, setFirstAvailableModule] = useState<AvailableModule | null>(null);
  const [lastAvailableModule, setLastAvailableModule] = useState<AvailableModule | null>(null);
  const [selectableModule, setSelectableModule] = useState<AvailableModule | null>(null);
  const [fillDirection, setFillDirection] = useState<'TOP' | 'BOTTOM'>('TOP');
  const { virtualPositions } = useAppSelector(({ product }) => product);
  const [topFilledPositions, setTopFilledPositions] = useState<[]>(virtualPositions[0]);
  const [bottomFilledPositions, setBottomFilledPositions] = useState<[]>(virtualPositions[1]);

  const dispatch = useAppDispatch();

  const toggleModal = () => setModalVisibility(!modalVisibility);

  useEffect(() => {
    dispatch(
      updateVirtualPositions([topFilledPositions, bottomFilledPositions])
    );
  }, [topFilledPositions, bottomFilledPositions]);

  useEffect(() => {
    // all modules that have not yet been selected...
    const allAvailableModules = availableModules.filter((am) => !Object.keys(selectedModules).map((k) => Number(k)).includes(am.position));
    // store the top / bottom most
    setFirstAvailableModule(allAvailableModules[0]);
    setLastAvailableModule(allAvailableModules[allAvailableModules.length - 1]);
    // auto select the top / bottom most and set the other as selectable depending on fill direction
    setSelectableModule(fillDirection === 'TOP' ? lastAvailableModule : firstAvailableModule);
    return setCurrentSelection(fillDirection === 'TOP' ? firstAvailableModule : lastAvailableModule);
  });

  const onModuleSelect = (selected : Module) => {
    const position = currentSelection?.position;
    if (!position) return;
    const newFilledPosiitons = [position];
    if (selected.no_of_spaces > 1) {
      const nextPosition = fillDirection === 'TOP' ? 1 : -1;
      newFilledPosiitons.push(position + nextPosition);
      // if the selected module fills 2 spaces, we remove the availableModule where the extra position is filled...
      setAvailableModules(availableModules.filter((n) => n.position !== position + nextPosition));
    }

    if (fillDirection === 'TOP') setTopFilledPositions(topFilledPositions.concat(newFilledPosiitons));
    if (fillDirection === 'BOTTOM') setBottomFilledPositions(bottomFilledPositions.concat(newFilledPosiitons));
    toggleModal();
    dispatch(updateSelectedModules({ position, selected }));
  };

  const selected = (m: Module): boolean => (
    !!(
      currentSelection
        && selectedModules
        && selectedModules[currentSelection.position]?.id === m.id
    )
  );

  const onModulePress = (value: AvailableModule) => {
    if (value === firstAvailableModule) setFillDirection('TOP');
    if (value === lastAvailableModule) setFillDirection('BOTTOM');
    toggleModal();
  };

  /*
    Only the top / bottom most selected module can be deselected
    when eg position 1 holds a 1 space module and position 2 holds a 2 space module the filled positions would be [1, 2, 3]
    in this case when canDeselect is called with argument of '2' we want to allow it to be deselected
    canDeselect is called in a loop with availableModules and when the user selects a 2 space module for position 2, we remove the availableModule in position 3.
    The same happens when filling from the bottom however the logic is inverted.
  */
  const canDeselect = (position : number) : boolean => {
    if (position === Math.max(...topFilledPositions) || (position === Math.max(...topFilledPositions) - 1 && !availableModules.map((am) => am.position).includes(position + 1))) {
      return true;
    }
    if (position === Math.min(...bottomFilledPositions) || (position === Math.min(...bottomFilledPositions) + 1 && !availableModules.map((am) => am.position).includes(position - 1))) {
      return true;
    }
    return false;
  };

  const isDisabled = (position : number): boolean => firstAvailableModule?.position !== position && lastAvailableModule?.position !== position;

  const afterDeselect = (position : number) : void => {
    if (position === Math.max(...topFilledPositions)) {
      setTopFilledPositions(topFilledPositions.filter((p) => p !== position));
      return setFillDirection('TOP');
    }
    if (position === Math.min(...bottomFilledPositions)) {
      setBottomFilledPositions(bottomFilledPositions.filter((p) => p !== position));
      return setFillDirection('BOTTOM');
    }
    if (Object.values(selectedModules).find((sm) => sm.position === position).no_of_spaces > 1) {
      const nextPosition = position + 1 === Math.max(...topFilledPositions) ? 1 : -1;
      availableModules.push(size.available_modules.find((am) => am.position === position + nextPosition));
      setAvailableModules(availableModules.sort((a, b) => a.position - b.position));

      if (position + nextPosition === Math.max(...topFilledPositions)) {
        setTopFilledPositions(topFilledPositions.filter((p) => ![position, position + nextPosition].includes(p)));
        return setFillDirection('TOP');
      }
      if (position + nextPosition === Math.min(...bottomFilledPositions)) {
        setBottomFilledPositions(bottomFilledPositions.filter((p) => ![position, position + nextPosition].includes(p)));
        return setFillDirection('BOTTOM');
      }
    }
  };

  const renderModuleDetails = (m : Module) => (
    <ModuleDetails
      key={m.id}
      module={m}
      onPress={() => { onModuleSelect(m); }}
      selected={selected(m)}
    />
  );

  const spacesRemaining = (): number => availableModules.length - Object.values(selectedModules).length;

  const moduleAtPosition = (position: number): SelectedModule => Object.values(selectedModules).find((sm) => sm.position === position);

  function findModuleAtEndOf(stack: number[]): SelectedModule | null {
    let i = 1;
    while (i) {
      const position = stack[stack.length - i];
      const module = moduleAtPosition(position);
      if (module !== undefined) {
        return module;
      }
      i += 1;
    }
    return null;
  }

  const aboveMe = (moduleSpaces: number): number | string | null => {
    if (currentSelection === null || (fillDirection === 'BOTTOM' && spacesRemaining() > moduleSpaces)) return null;

    if (topFilledPositions.length) return findModuleAtEndOf(topFilledPositions).id;

    return 'TOP';
  };

  const belowMe = (moduleSpaces: number): number | string | null => {
    if (currentSelection === null || (fillDirection === 'TOP' && spacesRemaining() > moduleSpaces)) return null;

    if (bottomFilledPositions.length) return findModuleAtEndOf(bottomFilledPositions).id;

    return 'BOTTOM';
  };

  const canFitNext = (moduleSpaces: number): boolean => spacesRemaining() >= moduleSpaces;

  function validModule(module: Module) {
    if (!canFitNext(module.no_of_spaces)) return false;

    if (!module.constraints.length) return true;

    let anyAllowRules = false;
    let allowRuleMatched = false;

    for (let i = 0; i < module.constraints.length; i += 1) {
      const constraint = module.constraints[i];
      if (constraint.allowed) {
        anyAllowRules = true;
      }
      const test = constraint.direction === 'BELOW' ? aboveMe : belowMe;
      const nextToMe = test(module.no_of_spaces);
      if ((constraint.target && constraint.target === nextToMe) || (constraint.related_safe_module_id && constraint.related_safe_module_id === nextToMe)) {
        if (!constraint.allowed) return false;

        allowRuleMatched = true;
      }
    }
    return !anyAllowRules || allowRuleMatched;
  }

  const allowedModulesForCurrentConfigration = () => allowedModules.filter((m) => validModule(m));

  return (
    <>
      <View
        style={{
          width: width > breakPoint ? '50%' : '100%', position: 'absolute', left: width > breakPoint ? '5%' : null, top: 0, alignContent: 'center',
        }}
      >
        <ModuleDrawerSelect
          afterDeselect={afterDeselect}
          availableModules={availableModules}
          canDeselect={canDeselect}
          currentSelection={currentSelection}
          displayTick={false}
          isDisabled={isDisabled}
          onModulePress={onModulePress}
          selectableModule={selectableModule}
          selectedModules={selectedModules}
          size={size}
        />
      </View>
      {
        width <= 700 ? (
          <Modal
            display={modalVisibility}
          >
            <ScrollView>
              <View style={{ marginBottom: 50 }}>
                {
                  allowedModulesForCurrentConfigration().map((m) => (
                    renderModuleDetails(m)
                  ))
                }
              </View>
            </ScrollView>
          </Modal>
        ) : (
          <ScrollView style={{ alignSelf: 'flex-start', marginBottom: 10, width: '50%', position: 'absolute', right: 0, height: '60vh' }}>
            {
              allowedModulesForCurrentConfigration().map((m) => (
                renderModuleDetails(m)
              ))
            }
          </ScrollView>
        )
      }
      <View
        style={
        [styles.moduleLayoutImageContainer,
          {
            left: width > breakPoint ? '5%' : 'auto',
            position: 'absolute',
            height: responsiveDimensions(adjustedHeight, width),
            width: responsiveDimensions(450, width),
            alignSelf: 'center',
          },
        ]
      }
      >
        <Image
          style={{ height: '100%', width: '100%' }}
          source={{ uri: size.module_preview_image }}
          resizeMode="contain"
        />
      </View>
    </>
  );
}
