import React from 'react';
import {
  Image, View, ScrollView, StyleSheet,
} from 'react-native';
import { AvailableModule, Module, SelectedModules, Size } from '../types';
import { updateSelectedModules } from '../store/productSlice';
import { useAppDispatch } from '../hooks/store';
import ModuleDrawerSelect from './ModuleDrawerSelect';
import responsiveDimensions from '../helpers/responsiveDimensions';
import Modal from './Modal';
import ModuleDetails from './ModuleDetails';

type Props = {
  adjustedHeight: number;
  allowedModules: [any];
  availableModules: [AvailableModule];
  breakPoint: number;
  currentSelection: AvailableModule | null;
  modalVisibility: boolean;
  onModulePress(): void;
  onModuleSelect(): void;
  selectedModules: SelectedModules;
  setCurrentSelection(): void;
  setAvailableModules(): void;
  setModalVisibility(): void;
  size: Size;
  width: number;
}

const styles = StyleSheet.create({
  container: {
    height: 600,
    resizeMode: 'contain',
    width: 300,
  },
  moduleLayoutImageContainer: {
    top: 0,
    zIndex: -1,
  },
});

export default function ModuleSelector({
  adjustedHeight, allowedModules, availableModules, breakPoint, currentSelection, modalVisibility,
  selectedModules, setCurrentSelection, setModalVisibility, size, width,
} : Props) {
  const dispatch = useAppDispatch();
  const displayModal = () => !!(currentSelection && modalVisibility);

  const onModuleSelect = (selected : Module) => {
    const position = currentSelection?.position;
    if (!position) return;
    setModalVisibility(false);
    dispatch(updateSelectedModules({ position, selected }));
  };

  const selected = (m: Module): boolean => (
    !!(
      currentSelection
        && selectedModules
        && selectedModules[currentSelection.position]?.id === m.id
    )
  );

  const onModulePress = (value: AvailableModule) => {
    setModalVisibility(true);
    setCurrentSelection(value);
  };

  const renderModuleDetails = (m : Module) => (
    <ModuleDetails
      key={m.id}
      module={m}
      onPress={() => { onModuleSelect(m); }}
      selected={selected(m)}
    />
  );
  return (
    <>
      <View
        style={{
          width: width > breakPoint ? '50%' : '100%', position: 'absolute', left: width > breakPoint ? '5%' : null, top: 0, alignContent: 'center',
        }}
      >
        <ModuleDrawerSelect
          availableModules={availableModules}
          currentSelection={currentSelection}
          displayTick
          onModulePress={onModulePress}
          selectedModules={selectedModules}
          size={size}
        />
      </View>
      {
        width <= 700 ? (
          <Modal
            display={displayModal()}
          >
            <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
              <View style={{ marginBottom: 10 }}>
                {
                  allowedModules.map((m) => (
                    renderModuleDetails(m)
                  ))
                }
              </View>
            </ScrollView>
          </Modal>
        ) : (
          <ScrollView style={{ alignSelf: 'flex-start', marginBottom: 10, width: '50%', position: 'absolute', right: 0, height: '60vh' }}>
            {
              allowedModules.map((m) => (
                renderModuleDetails(m)
              ))
            }
          </ScrollView>
        )
      }
      <View
        style={
        [styles.moduleLayoutImageContainer,
          {
            left: width > breakPoint ? '5%' : 'auto',
            position: 'absolute',
            height: responsiveDimensions(adjustedHeight, width),
            width: responsiveDimensions(450, width),
            alignSelf: 'center',
          },
        ]
      }
      >
        <Image
          style={{ height: '100%', width: '100%' }}
          source={{ uri: size.module_preview_image }}
          resizeMode="contain"
        />
      </View>
    </>
  );
}
