import React, { useEffect, useState } from 'react';
import { Image, View } from 'react-native';
import fetchModuleImage from '../helpers/fetchModuleImage';
import { Module } from '../../types/index';

const defaultProps = {
  height: 55,
  width: 130,
};

type Props = {
  module: Module;
  productId: string;
  gradeSizeId: string;
  height?: number;
  width?: number;
} & typeof defaultProps;

const ModulePreview = ({
  module, productId, gradeSizeId, height, width,
} : Props) => {
  const [moduleImage, setModuleImage] = useState(null);

  useEffect(() => {
    fetchModuleImage(module.selectedFinish.id, module.id, productId, gradeSizeId, true)
      .then((json: any) => {
        setModuleImage(json);
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    moduleImage
      ? (
        <View style={{ width, height }}>
          <Image
            style={{
              width: '100%', height: '100%',
            }}
            source={{ uri: moduleImage.module_colour_variant_url }}
            resizeMode="cover"
          />
        </View>
      ) : null
  );
};

ModulePreview.defaultProps = defaultProps;

export default ModulePreview;
