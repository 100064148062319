import React from 'react';
import {
  Image, Platform, Pressable, StyleSheet, Text, Touchable, View,
} from 'react-native';
import { Size } from '../types/index';

type Props = {
  size: Size;
  onPress(): void;
  active: boolean;
  index: number;
};

const logo = require('../assets/icon.png');

const isApp = Platform.OS !== 'web';

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: '#fff',
    borderRadius: 20,
    borderWidth: 2,
    borderColor: '#fff',
    flex: 1,
    justifyContent: 'center',
    marginHorizontal: 10,
    paddingHorizontal: 5,
    paddingVertical: isApp ? 5 : 5,
  },
  sizeOptionText: {
    color: '#5f5f5f',
    fontFamily: 'nimbus',
    fontSize: isApp ? 14 : 16,
    marginVertical: 5,
    textAlign: 'center',
  },
  sizeOptionHeading: {
    color: '#5f5f5f',
    fontFamily: 'nimbus',
    fontWeight: 'bold',
    marginTop: 5,
    textAlign: 'center',
  },
});

export default function SizeOption({
  size, onPress, active, index,
} : Props) {
  const pluralise = (count: number, singular: string) => (count === 1 ? singular : `${singular}s`);
  const hasLayouts = (size) => size.layouts.length > 0;

  return (
    <Pressable
      onPress={onPress}
      style={[styles.container, { borderColor: active ? '#00959a' : '#fff' }]}
    >
      <View style={
        {
          height: isApp ? 176 : 220,
          padding: 10,
          paddingBottom: isApp ? 5 : 10,
          width: isApp ? 160 : 200,
        }
      }
      >
        <Image
          style={{ height: '100%', width: '100%', resizeMode: 'contain' }}
          source={size.module_layout_image ? { uri: size.module_layout_image } : logo}
        />
      </View>
      <View style={{ width: '100%', padding: 10, paddingTop: isApp ? 5 : 10 }}>
        {
          size.external_dimensions ? (
            <>
              <Text style={styles.sizeOptionHeading}>External dimensions</Text>
              <Text style={styles.sizeOptionText}>{size.external_dimensions}</Text>
            </>
          )
            : <></>
        }
        {
          size.internal_dimensions ? (
            <>
              <Text style={styles.sizeOptionHeading}>Internal dimensions</Text>
              <Text style={styles.sizeOptionText}>{size.internal_dimensions}</Text>
            </>
          )
            : <></>
        }
        {
          size.weight ? (
            <>
              <Text style={styles.sizeOptionHeading}>Weight</Text>
              <Text style={styles.sizeOptionText}>
                {size.weight}
                KG
              </Text>
            </>
          )
            : <></>
        }

        {
          hasLayouts(size)
            ? (
              <Text style={styles.sizeOptionText}>
                {`${size.layouts.length} ${pluralise(size.layouts.length, 'Layout')}`}
              </Text>
            )
            : (
              <>
                <Text style={styles.sizeOptionHeading}>Modules</Text>
                <Text style={styles.sizeOptionText}>{`${size.available_modules.length}`}</Text>
              </>
            )
        }
      </View>
    </Pressable>
  );
}
