import baseUrl from './baseUrl';

export default async function fetchModuleImage(finishId, moduleId, productId, gradeSizeId, useApi) : Promise<any> {
  if (useApi) {
    const apiBase = `${baseUrl}/get_image?safe_id=${productId}&finish=${finishId}&safe_module=${moduleId}&grade_size=${gradeSizeId}`;

    try {
      const url = apiBase;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
      const data: any = await response.json();

      return data;
    } catch (e) {
      console.log(`ERROR${e}`);

      return Promise.reject();
    }
  } else {
    return {};
  }
}
