import { createSlice } from '@reduxjs/toolkit';

interface CustomerState {
  name: string;
  email: string;
  address_line_1: string;
  town_city: string;
  postcode: string;
  company_name: string;
  phone: string;
}

const initialState : CustomerState = {
  name: '',
  email: '',
  address_line_1: '',
  town_city: '',
  postcode: '',
  company_name: '',
  phone: '',
};

export const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    refresh: (state) => {
      state.address = '';
      state.email = '';
      state.name = '';
      state.phone = '';
      state.address_line_1 = '';
      state.postcode = '';
      state.company_name = '';
      state.town_city = '';
    },
    updateName: (state, { payload: name }) => {
      state.name = name;
    },
    updateEmail: (state, { payload: email }) => {
      state.email = email;
    },
    updateAddress: (state, { payload: address }) => {
      state.address_line_1 = address;
    },
    updatePostcode: (state, { payload: postcode }) => {
      state.postcode = postcode;
    },
    updateTownCity: (state, { payload: town_city }) => {
      state.town_city = town_city;
    },
    updateCompanyName: (state, { payload: company_name }) => {
      state.company_name = company_name;
    },
    updatePhone: (state, { payload: phone }) => {
      state.phone = phone;
    },
  },
});

export const {
  refresh, updateCompanyName, updatePostcode,
  updateTownCity,
  updateName, updateAddress, updateEmail, updatePhone,
} = customerSlice.actions;

export default customerSlice.reducer;
