import { Platform } from 'react-native';
import * as FileSystem from 'expo-file-system';
import * as Sharing from 'expo-sharing';
import { SubmittedData } from "../types";
import baseUrl from './baseUrl';

// Download api enquiry response to pdf, we no longer use this but leaving code here
const handleWebResponse = (blob: Blob) => {
  const fileUrl = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = fileUrl;
  a.download = 'burton-safes-quote.pdf';
  a.click();
  URL.revokeObjectURL(fileUrl);
  a.remove();
};

const handleNativeResponse = (blob: Blob) => {
  const fileLocation = `${FileSystem.documentDirectory}`;
  const fileUrl = `${fileLocation}/burton-safes-quote.pdf`;
  const fileReader = new FileReader();
  fileReader.onload = async () => {
    const { exists } = await FileSystem.getInfoAsync(fileLocation);
    if (!exists) {
      await FileSystem.makeDirectoryAsync(fileLocation);
    }
    await FileSystem.writeAsStringAsync(fileUrl, fileReader.result.split(',')[1], { encoding: FileSystem.EncodingType.Base64 });
    const supported = await Sharing.isAvailableAsync();
    if (supported && fileUrl) {
      console.log('Sharing...');
      Sharing.shareAsync(fileUrl);
    } else {
      console.log('Unable to share');
    }
  };
  fileReader.readAsDataURL(blob);
};

export default async function submitDetails(data : SubmittedData) {
  const apiBase = `${baseUrl}/enquiries`;

  try {
    const url = apiBase;
    await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  } catch (e) {
    console.log(`ERROR: ${e}`);

    return Promise.reject();
  }
  return Promise.resolve();
}
