import React from 'react';
import {
  View, ScrollView,
} from 'react-native';
import { useAppSelector, useAppDispatch } from '../../hooks/store';
import { updateSafeColour } from '../../store/productSlice';
import { SafeColour } from '../../types';
import ColourOption from '../../components/ColourOption';

export default function SafeColourSelectionScreen() {
  const dispatch = useAppDispatch();
  const selectedColour = useAppSelector(({ product }) => product.safeColour);
  const safeColours = useAppSelector(({ product }) => product.size?.safe_colours);

  const onColourSelect = (colour: SafeColour) => {
    dispatch(updateSafeColour(colour));
  };

  const selected = (colour: SafeColour): boolean => (
    selectedColour?.id === colour.id
  );

  return (
    <ScrollView>
      <View
        style={{
          flexDirection: 'row', flexWrap: 'wrap', flex: 1, justifyContent: 'center',
        }}
      >
        {
          safeColours?.map((colour) => (
            <ColourOption
              key={colour.id}
              colour={colour}
              onPress={() => { onColourSelect(colour); }}
              active={selected(colour)}
            />
          ))
        }
      </View>
    </ScrollView>
  );
}
