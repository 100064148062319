import React from 'react';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import {
  TextInput, StyleSheet, View, Text, useWindowDimensions, ScrollView,
} from 'react-native';
import { useAppSelector, useAppDispatch } from '../hooks/store';
import {
  updateAddress, updateCompanyName, updateEmail, updateName,
  updatePhone, updatePostcode, updateTownCity,
} from '../store/customerSlice';
import { RootStackParamList } from '../types/routes';
import BackButton from '../components/BackButton';
import ConditionalButton from '../components/ConditionalButton';
import AppContainer from '../components/AppContainer';
import ProductPreview from '../components/ProductPreview';
import NavigationBar from '../components/NavigationBar';
import HeaderSection from '../components/HeaderSection';
import ScrollViewHeader from '../components/ScrollViewHeader';

type Props = NativeStackScreenProps<RootStackParamList, 'Customer Details'>

const styles = StyleSheet.create({
  heading: {
    fontFamily: 'eurostile',
    fontSize: 24,
    marginVertical: 15,
    textAlign: 'center',
  },
  input: {
    height: 40,
    margin: 12,
    borderWidth: 1,
    padding: 10,
    width: 300,
  },
  inputLabel: {
    fontWeight: 'bold',
    paddingLeft: 12,
  },
});

export default function CustomerDetailsScreen({ navigation } : Props) {
  const dispatch = useAppDispatch();
  const customerName = useAppSelector(({ customer }) => customer.name);
  const customerEmail = useAppSelector(({ customer }) => customer.email);
  const customerAddress = useAppSelector(({ customer }) => customer.address_line_1);
  const customerTownCity = useAppSelector(({ customer }) => customer.town_city);
  const customerCompanyName = useAppSelector(({ customer }) => customer.company_name);
  const customerPostcode = useAppSelector(({ customer }) => customer.postcode);
  const customerPhone = useAppSelector(({ customer }) => customer.phone);

  const { width } = useWindowDimensions();
  const breakPoint = width > 700;
  const next = () => navigation.navigate('Summary');
  const canContinue = () => {
    const requiredFields = [
      customerAddress, customerEmail, customerName,
      customerPhone, customerPostcode, customerTownCity,
    ];
    return requiredFields.every((value) => !!value);
  };

  const renderCustomerDetails = () => (
    <ScrollView>
      <View style={{ alignItems: 'center', justifyContent: 'center' }}>
        <ScrollViewHeader title="Your Design" />
        <View style={{
          width: '100%', justifyContent: 'center', display: 'flex', flexDirection: width > 700 ? 'row' : 'column', marginTop: 40, marginBottom: 80,
        }}
        >
          <View style={{ width: breakPoint ? '50%' : '100%' }}>
            <ProductPreview />
          </View>
          <View style={{ alignItems: 'center', width: breakPoint ? '50%' : '100%', marginTop: breakPoint ? 0 : 30 }}>
            <View style={{ alignItems: 'center', width: breakPoint ? '50%' : '100%' }}>
              <View>
                {
                  breakPoint ? (
                    <>
                      <Text style={styles.inputLabel}>Name</Text>
                    </>
                  ) : (
                    <></>
                  )
                }
                <TextInput
                  style={styles.input}
                  onChangeText={(value) => dispatch(updateName(value))}
                  value={customerName}
                  placeholder="Name"
                  autoCapitalize="words"
                  autoComplete="name"
                  accessible
                  accessibilityLabel="Customer name input"
                  textContentType="name"
                />
              </View>
              <View>
                {
                  breakPoint ? (
                    <>
                      <Text style={styles.inputLabel}>Company name</Text>
                    </>
                  ) : (
                    <></>
                  )
                }
                <TextInput
                  style={styles.input}
                  onChangeText={(value) => dispatch(updateCompanyName(value))}
                  value={customerCompanyName}
                  placeholder="Company name (optional)"
                  accessible
                  accessibilityLabel="Customer company name input"
                  textContentType="organizationName"
                />
              </View>
              <View>
                {
                  breakPoint ? (
                    <>
                      <Text style={styles.inputLabel}>Street / house</Text>
                    </>
                  ) : (
                    <></>
                  )
                }
                <TextInput
                  style={styles.input}
                  onChangeText={(value) => dispatch(updateAddress(value))}
                  value={customerAddress}
                  placeholder="Street / house"
                  autoComplete="street-address"
                  accessible
                  accessibilityLabel="Customer address input"
                  textContentType="fullStreetAddress"
                />
              </View>
              <View>
                {
                  breakPoint ? (
                    <>
                      <Text style={styles.inputLabel}>Town / city</Text>
                    </>
                  ) : (
                    <></>
                  )
                }
                <TextInput
                  style={styles.input}
                  onChangeText={(value) => dispatch(updateTownCity(value))}
                  value={customerTownCity}
                  placeholder="Town / city"
                  accessible
                  accessibilityLabel="Customer town / city input"
                  textContentType="addressCity"
                />
              </View>
              <View>
                {
                  breakPoint ? (
                    <>
                      <Text style={styles.inputLabel}>Postcode</Text>
                    </>
                  ) : (
                    <></>
                  )
                }
                <TextInput
                  style={styles.input}
                  onChangeText={(value) => dispatch(updatePostcode(value))}
                  value={customerPostcode}
                  placeholder="Postcode"
                  autoComplete="postal-code"
                  accessible
                  accessibilityLabel="Customer postcode input"
                  textContentType="postalCode"
                />
              </View>
              <View>
                {
                  breakPoint ? (
                    <>
                      <Text style={styles.inputLabel}>Email</Text>
                    </>
                  ) : (
                    <></>
                  )
                }
                <TextInput
                  autoComplete="email"
                  keyboardType="email-address"
                  style={styles.input}
                  onChangeText={(value) => dispatch(updateEmail(value))}
                  value={customerEmail}
                  placeholder="Email"
                  accessible
                  accessibilityLabel="Customer email input"
                  textContentType="emailAddress"
                />
              </View>
              <View>
                {
                  breakPoint ? (
                    <>
                      <Text style={styles.inputLabel}>Phone</Text>
                    </>
                  ) : (
                    <></>
                  )
                }
                <TextInput
                  style={styles.input}
                  onChangeText={(value) => dispatch(updatePhone(value))}
                  value={customerPhone}
                  placeholder="Phone"
                  keyboardType="number-pad"
                  accessible
                  autoComplete="tel"
                  accessibilityLabel="Customer phone number"
                  textContentType="telephoneNumber"
                />
              </View>
            </View>
          </View>
        </View>
      </View>
    </ScrollView>
  );

  return (
    <>
      <HeaderSection navigation={navigation} />
      <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
        <AppContainer>
          {renderCustomerDetails()}
        </AppContainer>
      </ScrollView>
      <NavigationBar>
        <BackButton />
        <ConditionalButton condition={canContinue()} onPress={next} title="Save" />
      </NavigationBar>
    </>
  );
}
