import React from 'react';
import {
  useWindowDimensions, Text, View, Pressable,
} from 'react-native';
import { Button as EButton, Icon } from 'react-native-elements';
import { useAppDispatch } from '../hooks/store';
import { deselectModule } from '../store/productSlice';
import { AvailableModule, SelectedModules, Size } from '../types';
import isApp from '../helpers/isApp'
import { allDrawersSelectedForModule, hasDrawers } from '../helpers/module_helpers';
import responsiveDimensions from '../helpers/responsiveDimensions';
import ModuleBox from './ModuleBox';

const defaultProps = {
  displayTick: true,
  drawerSelect: false,
  selectableModule: null,
};

type Props = {
  availableModules: [AvailableModule];
  canDeselect(): boolean;
  currentSelection: AvailableModule;
  displayTick: boolean;
  drawerSelect: boolean;
  onModulePress(): void;
  isDisabled(): void;
  renderModuleTitle(): void;
  selectableModule: AvailableModule;
  selectedModules: SelectedModules;
  size: Size;
} & typeof defaultProps;

export default function ModuleDrawerSelect(
  {
    afterDeselect, availableModules, canDeselect, currentSelection, displayTick, drawerSelect,
    isDisabled, onModulePress, selectableModule, selectedModules, size,
  } : Props,
) {
  const dispatch = useAppDispatch();
  const { width } = useWindowDimensions();
  const breakPoint = 700;
  isDisabled = isDisabled || (() => false);
  canDeselect = canDeselect || (() => false);
  afterDeselect = afterDeselect || (() => null);
  const isActive = (position : number) => (
    !!(currentSelection && currentSelection.position === position)
  );

  const adjustedHeight = size.safe_height
    || ((size.available_modules.length || 0) * size.module_height) + size.module_height;
  const moduleSelected = (position: number) : boolean => Object.values(selectedModules).map((n) => n.position).some((n) => n === position);

  const removeModuleFromSelection = (position : number, callBack : void) => {
    callBack(position);
    dispatch(deselectModule({ position }));
  };

  return (
    <View style={
      {
        alignSelf: width > breakPoint ? 'left' : 'center',
        width: responsiveDimensions(450, width),
        justifyContent: 'center',
        paddingLeft: responsiveDimensions(size.padding_left || 25, width),
        height: responsiveDimensions(adjustedHeight, width),
      }
    }
    >
      {
        availableModules.map((am) => (
          <View key={am.position}>
            <ModuleBox
              active={isActive(am.position)}
              isDisabled={isDisabled(am.position)}
              onPress={() => { onModulePress(am); }}
              moduleWidth={size.module_width}
              moduleHeight={size.module_height}
              numberOfSpaces={selectedModules[am.position]?.no_of_spaces}
              selectable={am === selectableModule}
            >
              <View
                style={{ alignItems: 'center', opacity: hasDrawers(am) || !drawerSelect ? 1 : 0.5, width: isApp ? '85%' : '95%', flexDirection: 'row', justifyContent: !drawerSelect && !displayTick ? 'space-between' : 'flex-start' }}
              >
                <>
                  {
                    (drawerSelect && allDrawersSelectedForModule(am)) || (!drawerSelect && displayTick) ? (
                      <Icon
                        tvParallaxProperties
                        name={moduleSelected(am.position) ? 'check' : 'ellipsis1'}
                        type="antdesign"
                        color="#02969e"
                        size={width > 900 ? 24 : 18}
                      />
                    )
                      : <></>
                  }
                  {
                    (!drawerSelect && !displayTick && !moduleSelected(am.position)) ? (
                      <Icon
                        tvParallaxProperties
                        name="ellipsis1"
                        type="antdesign"
                        color="#02969e"
                        size={width > 900 ? 24 : 18}
                      />
                    )
                      : <></>
                  }
                  <Text style={
                    {
                      color: '#02969e', fontFamily: 'nimbus', marginLeft: (drawerSelect && allDrawersSelectedForModule(am)) || !drawerSelect ? 3 : 26, paddingTop: 2, fontSize: width > 900 ? 14 : 11,
                    }
                  }
                  >
                    {selectedModules[am.position]?.name}
                  </Text>
                  {
                    !drawerSelect && moduleSelected(am.position) && canDeselect(am.position) ? (
                      <Pressable
                        onPress={() => removeModuleFromSelection(am.position, afterDeselect)}
                      >
                        <Icon
                          tvParallaxProperties
                          name="closecircleo"
                          type="antdesign"
                          color="#02969e"
                          size={width > 900 ? 18 : 12}
                        />
                      </Pressable>
                    )
                      : <></>
                  }
                </>
              </View>
            </ModuleBox>
          </View>
        ))
      }
    </View>
  );
}
