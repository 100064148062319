import React from 'react';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import {
  ScrollView, useWindowDimensions,
} from 'react-native';
import { useAppDispatch, useAppSelector } from '../../hooks/store';
import BackButton from '../../components/BackButton';
import SizeOption from '../../components/SizeOption';
import { ProductStackParamList } from '../../types/routes'
import { updateSize, updateVirtualPositions } from '../../store/productSlice';
import ConditionalButton from '../../components/ConditionalButton';
import AppContainer from '../../components/AppContainer';
import { Size } from '../../types';
import NavigationBar from '../../components/NavigationBar';
import HeaderSection from '../../components/HeaderSection';
import ScrollViewHeader from '../../components/ScrollViewHeader';

type Props = NativeStackScreenProps<ProductStackParamList, 'Select Size'>

const next = (selectedSize : boolean, navigation: Props['navigation'], size: Size) => {
  if (!selectedSize) return;
  const destination = size.layouts?.length ? 'Select Layout' : 'Select Modules';
  navigation.navigate(destination);
};

export default function SizeSelectScreen({ navigation }: Props) {
  const dispatch = useAppDispatch();
  const { grade, size } = useAppSelector(({ product }) => product);
  const sizes = grade?.sizes;
  const { width } = useWindowDimensions();

  // returns true if a size has been selected and belongs to the selected grade
  const sizeSelected = () => (!!size && !!grade?.sizes?.find((s) => s.id === size.id));

  const onSizeSelect = (item: Size) => {
    if (item !== size) dispatch(updateVirtualPositions([[], []]));
    dispatch(updateSize(item));
  };
  return (
    <>
      <HeaderSection navigation={navigation} />
      <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
        <AppContainer>
          <ScrollView contentContainerStyle={{ flex: 1, alignItems: 'center', justifyContent: 'flex-start' }}>
            <ScrollViewHeader title="Choose a Size of Safe" />
            <ScrollView horizontal style={{ marginTop: width > 400 ? 30 : 10, marginBottom: 10, maxWidth: '100%', flexGrow: 0, paddingBottom: 20 }}>
              {sizes?.map((item, index) => (
                <SizeOption
                  size={item}
                  key={item.id.toString()}
                  onPress={() => { onSizeSelect(item); }}
                  active={!!(size && size.id === item.id)}
                  index={index}
                />
              ))}
            </ScrollView>
          </ScrollView>
        </AppContainer>
      </ScrollView>
      <NavigationBar>
        <BackButton />
        <ConditionalButton condition={sizeSelected()} onPress={() => next(sizeSelected(), navigation, size)} title="Next" />
      </NavigationBar>
    </>
  );
}
