import React, { ReactNode } from 'react';
import {
  Pressable, useWindowDimensions,
} from 'react-native';

type Props = {
  onPress(): void;
  active: boolean;
  children?: ReactNode;
};

export default function ModuleBox({
  onPress, active, children,
} : Props) {
  const { width } = useWindowDimensions();

  const boxWidth = () => {
    if (width > 1100) {
      return 320;
    }
    if (width < 700) {
      return 280;
    }
    return 220;
  };
  return (
    <Pressable
      onPress={onPress}
      style={{
        alignItems: 'flex-start',
        backgroundColor: active ? '#aecccf' : '#fefefe',
        borderColor: '#02969e',
        borderRadius: 3,
        borderWidth: 2,
        height: width > 1100 || width < 700 ? 55 : 70,
        justifyContent: 'center',
        margin: 2,
        paddingLeft: 5,
        width: boxWidth(),
      }}
    >
      {children}
    </Pressable>
  );
}

ModuleBox.defaultProps = {
  children: null,
};
