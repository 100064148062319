import React, { useState, useEffect } from 'react';
import {
  View, Text, useWindowDimensions,
} from 'react-native';
import { Icon } from 'react-native-elements';
import { useAppSelector, useAppDispatch } from '../../hooks/store';
import { noOfDrawersSelected } from '../../helpers/module_helpers';
import { updateSelectedDrawers } from '../../store/productSlice';
import { Drawer, SelectedDrawer, SelectedModule } from '../../types';
import DrawerDetails from '../../components/DrawerDetails';
import DrawerBox from '../../components/DrawerBox';

type Props = {
  currentSelection: SelectedModule;
}

export default function DrawerOptionsScreen({ currentSelection } : Props) {
  const dispatch = useAppDispatch();
  const { product } = useAppSelector(({ product }) => product);
  const selectedModules = useAppSelector(({ product }) => product.selectedModules);
  const selectedSize = useAppSelector(({ product }) => product.size);
  const [drawerCount, setDrawerCount] = useState<number>(0);
  const [currentDrawerSelection, setCurrentDrawerSelection] = useState<number | null>(null);
  const drawersByPosition = (position: number) => {
    const module = Object.values(selectedModules).find((sm: SelectedModule) => sm.position === position);
    return noOfDrawersSelected(module);
  };
  const { width } = useWindowDimensions();
  useEffect(() => {
    if (currentSelection) {
      setDrawerCount(currentSelection.drawers || 0);
      setCurrentDrawerSelection(null);
    }
  }, [currentSelection]);

  const displayDrawerDetails = () => {
    if (currentSelection.drawers === 1 && currentDrawerSelection !== 1) {
      setCurrentDrawerSelection(1);
      return true;
    }
    return currentSelection && drawerCount && currentDrawerSelection;
  };

  const filteredDrawers = () => {
    if (!selectedSize?.drawers) return [];
    if (product?.name === 'Amario Lux') {
      return selectedSize.drawers.filter((d) => d.module_name?.toLowerCase() === currentSelection.name.toLowerCase());
    }
    return selectedSize.drawers;
  };

  const onDrawerSelect = (selectedDrawer : Drawer) => {
    if (!currentSelection) return;
    if (!currentDrawerSelection) return;
    const payload = {
      selectedModule: currentSelection,
      drawerPosition: currentDrawerSelection,
      selectedDrawer,
    };
    dispatch(updateSelectedDrawers(payload));
    setCurrentDrawerSelection(null);
  };

  const selected = (position: number): SelectedDrawer => (
    selectedModules[currentSelection.position].selectedDrawers[position]
  );

  const selectedDrawer = (position: number) => (
    selectedModules[currentSelection.position].selectedDrawers[position]
  );

  const renderDrawerTitle = (position: number) => {
    if (!currentSelection) return '';
    const drawer = selectedDrawer(position);
    return drawer ? <Text style={{ color: '#02969e', fontFamily: 'nimbus' }}>{ drawer.name }</Text> : null;
  };
  return (
    <View>
      <View style={{ width: width > 1100 ? '45%' : '100%', alignSelf: 'center', justifyContent: 'center', marginBottom: 20 }}>
        <Text style={{ fontSize: 16, fontWeight: 'bold', color: '#02969e', textAlign: width > 1100 ? 'left' : 'center', marginBottom: 10 }}>{currentSelection.name}</Text>
        <Text style={{ fontFamily: 'nimbus', fontWeight: 'bold', textAlign: width > 1100 ? 'left' : 'center' }}>
          Drawers:&nbsp;
          {`${drawersByPosition(currentSelection.position)} / ${currentSelection.drawers} selected`}
        </Text>
      </View>
      <View>
        { displayDrawerDetails() ? (
          <View>
            <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
              {filteredDrawers().map((drawer) => (
                <DrawerDetails
                  key={drawer.id}
                  drawer={drawer}
                  selected={drawer.id === selected(currentDrawerSelection)?.id}
                  onPress={() => { onDrawerSelect(drawer); }}
                />
              ))}
            </View>
          </View>
        )
          : (
            <View>
              <View style={{ alignItems: 'center', borderWidth: 1, alignSelf: 'center' }}>
                {[...Array(drawerCount)].map((_, i) => (
                  <DrawerBox
                    key={i.toString()}
                    active={i + 1 === currentDrawerSelection}
                    onPress={() => { setCurrentDrawerSelection(i + 1); }}
                  >
                    <View style={{ alignItems: 'center', flexDirection: 'row', paddingLeft: 10 }}>
                      <View>
                        <Icon name={selectedDrawer(i + 1) ? 'check' : 'ellipsis1'} color="#02969e" type="antdesign" />
                      </View>
                      <View style={{marginLeft: 20 }}>
                        { renderDrawerTitle(i + 1) }
                      </View>
                    </View>
                  </DrawerBox>
                ))}
              </View>
            </View>
          )}
      </View>
    </View>
  );
}
