import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Size, Product, Grade, SelectedModules, SelectedModule, Drawer, ModuleFinish, DrawerLining, SafeColour,
  Detailing,
} from '../types/index';

interface ProductState {
  product: Product | null;
  grade: Grade | null;
  size: Size | null;
  safeColour: SafeColour | null;
  detailing: Detailing | null;
  selectedModules: SelectedModules;
  virtualPositions: [[], []];
}

interface SelectedModulePayload {
  selectedModule: SelectedModule;
  position: number;
}

interface DrawerPayload {
  selectedModule: SelectedModule;
  drawerPosition: number;
  selectedDrawer: Drawer;
}
interface FinishPayload {
  selectedModule: SelectedModule;
  finish: ModuleFinish;
}

interface LiningPayload {
  selectedModule: SelectedModule;
  finish: DrawerLining;
}

interface DeselectModulePayload {
  position: number;
}

interface VirtualPositionsPayload {
  positions: [];
}

const initialState : ProductState = {
  product: null,
  grade: null,
  size: null,
  safeColour: null,
  detailing: null,
  selectedModules: {},
  virtualPositions: [[], []],
};

export const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    refresh: (state) => {
      state.product = null;
      state.grade = null;
      state.size = null;
      state.safeColour = null;
      state.detailing = null;
      state.selectedModules = {};
      state.virtualPositions = [[], []];
    },
    refreshSelectedModules: (state) => {
      state.selectedModules = {};
    },
    updateProduct: (state, { payload }) => {
      state.product = payload;
    },
    updateGrade: (state, { payload }) => {
      state.grade = payload;
    },
    updateSize: (state, { payload }: PayloadAction<Size>) => {
      if (state.size?.id !== payload.id) {
        state.selectedModules = {};
        state.safeColour = null;
      }
      state.size = payload;
    },
    updateSelectedModules: {
      prepare: ({ selected, position }) => {
        const additionalProperties = {
          position,
          selectedDrawers: {},
        };
        const selectedModule = { ...additionalProperties, ...selected };
        const payload = { position, selectedModule };
        return { payload };
      },
      reducer: (state, { payload }: PayloadAction<SelectedModulePayload>) => {
        const { position, selectedModule } = payload;
        state.selectedModules = { ...state.selectedModules, ...{ [position]: selectedModule } };
      },
    },
    deselectModule: (state, { payload }: PayloadAction<DeselectModulePayload>) => {
      const { position } = payload;
      const key = String(position);
      const { [key]: _, ...updatedModules } = state.selectedModules;
      state.selectedModules = updatedModules;
    },
    updateSelectedDrawers: (state, { payload }: PayloadAction<DrawerPayload>) => {
      const { selectedModule, drawerPosition, selectedDrawer } = payload;
      const drawer = { ...selectedDrawer, position: drawerPosition };
      state.selectedModules[selectedModule.position].selectedDrawers = {
        ...state.selectedModules[selectedModule.position].selectedDrawers,
        ...{ [drawerPosition]: drawer },
      };
    },
    updateSelectedFinish: (state, { payload }: PayloadAction<FinishPayload>) => {
      const { selectedModule, finish } = payload;
      state.selectedModules[selectedModule.position].selectedFinish = finish;
    },
    updateSelectedLining: (state, { payload }: PayloadAction<LiningPayload>) => {
      const { selectedModule, lining } = payload;
      state.selectedModules[selectedModule.position].selectedLining = lining;
    },
    updateSafeColour: (state, { payload }: PayloadAction<SafeColour>) => {
      state.safeColour = payload;
    },
    updateDetailing: (state, { payload }: PayloadAction<Detailing>) => {
      state.detailing = payload;
    },
    updateVirtualPositions: (state, { payload }: PayloadAction<VirtualPositionsPayload>) => {
      state.virtualPositions = payload;
    },
  },
});

export const {
  refresh, refreshSelectedModules, deselectModule, updateVirtualPositions,
  updateProduct, updateGrade, updateSize, updateSelectedModules,
  updateSelectedDrawers, updateSelectedFinish, updateSelectedLining,
  updateSafeColour, updateDetailing,
} = productSlice.actions;

export default productSlice.reducer;
