import * as Linking from 'expo-linking';
import _data from './safes.json';
import { Product } from '../types/index';
import baseUrl from './baseUrl';

interface Data {
  products: Product[]
}

export default async function fetchProducts(useApi) : Promise<Product[]> {
  const currentUrl = await Linking.getInitialURL();
  const params = Linking.parse(currentUrl).queryParams;
  if (useApi) {
    const apiBase = `${baseUrl}/get_details?admin_key=${params.admin}`;

    try {
      const url = apiBase;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
      const data: Data = await response.json();

      return data.products;
    } catch (e) {
      console.log(`ERROR${e}`);

      return Promise.reject();
    }
  } else {
    const data: Data = _data;
    return data.products;
  }
}
