import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import GradeSelectScreen from './products/GradeSelectScreen';
import SizeSelectScreen from './products/SizeSelectScreen';
import ModulesSelectScreen from './products/ModulesSelectScreen';
import DrawersSelectScreen from './products/DrawersSelectScreen';
import FinishSelectionScreen from './products/FinishSelectionScreen';
import LiningSelectionScreen from './products/LiningSelectionScreen';
import OptionsSelectScreen from './products/OptionsSelectScreen';
import LayoutSelectScreen from './products/LayoutSelectScreen';

const Stack = createNativeStackNavigator();

export default function ProductScreen() {
  return (
    <Stack.Navigator screenOptions={{ contentStyle: { backgroundColor: 'white' } }}>
      <Stack.Screen name="Select Grade" component={GradeSelectScreen} options={{ headerShown: false }} />
      <Stack.Screen name="Select Size" component={SizeSelectScreen} options={{ headerShown: false }} />
      <Stack.Screen name="Select Modules" component={ModulesSelectScreen} options={{ headerShown: false }} />
      <Stack.Screen name="Select Layout" component={LayoutSelectScreen} options={{ headerShown: false }} />
      <Stack.Screen name="Select Options" component={OptionsSelectScreen} options={{ headerShown: false }} />
      <Stack.Screen name="Select Drawers" component={DrawersSelectScreen} options={{ headerShown: false }} />
      <Stack.Screen name="Select Finish" component={FinishSelectionScreen} options={{ headerShown: false }} />
      <Stack.Screen name="Select Lining" component={LiningSelectionScreen} options={{ headerShown: false }} />
    </Stack.Navigator>
  );
}
