import React from 'react';
import { useWindowDimensions, View } from 'react-native';

type Props = {
  children: any;
}

export default function Navigation({
  children,
} : Props) {
  const { width } = useWindowDimensions();
  return (
    <View style={{ alignItems: 'center', backgroundColor: '#fff', bottom: 0, justifyContent: 'center', width: '100%' }}>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginVertical: 15,
          width: width > 400 ? 340 : 280,
        }}
      >
        { children }
      </View>
    </View>
  );
}
